import { getGetApiWorkspaceGetQueryKey, getGetApiWorkspaceListQueryKey, usePutApiWorkspaceRename, Workspace } from '@/API';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PropsWithChildren } from 'react';
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { EditWorkspaceNameForm } from "../WorkspaceList/EditWorkspaceNameForm";

export function EditWorkspaceNameButton({ workspace, children }: { workspace: Workspace } & PropsWithChildren) {
    const queryClient = useQueryClient()
    const [editName_opened, { open: editName_open, close: editName_close }] = useDisclosure(false);
    const rename_Mutation = usePutApiWorkspaceRename()
    return <>
        <div onClick={editName_open}>
            {children}
        </div>
        <Modal title="修改名稱" opened={editName_opened} onClose={editName_close}>
            <EditWorkspaceNameForm initValue={workspace.name ?? ""} onSubmit={async v => {
                editName_close()
                if (workspace.id) {
                    try {
                        await rename_Mutation.mutateAsync({ params: { id: workspace.id, name: v } })
                        notifications.show({ message: "修改成功" })
                        queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                        queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceGetQueryKey({ id: workspace.id }) })
                    } catch (e) {
                        notifications.show({ message: "修改失敗", color: "red" })
                    }
                }
            }} />
        </Modal>
    </>
}