import { useGetApiWorkspaceList, usePostApiWorkspaceCreate } from "@/API"
import { WorkspaceListCard } from "./WorkspaceListCard"
import { useAuthStore } from "@/Stores/AuthStore"
import { EditWorkspaceForm } from "../EditWorkspaceForm"
import { useDisclosure } from "@mantine/hooks"
import { Box, Breadcrumbs, Button, Divider, Flex, Group, LoadingOverlay, Modal, Text } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import { WorkspaceSearch } from "../Search"
import { PageTitle } from "@/Components/PageTitle"

export function WorkspaceList() {
    const queryClient = useQueryClient()
    const auth = useAuthStore();
    const navigate = useNavigate()
    const workspaceList = useGetApiWorkspaceList()
    const createWorkspace_Mutation = usePostApiWorkspaceCreate()
    const [create_opened, { open: create_open, close: create_close }] = useDisclosure(false);
    return <Box pos="relative">
        <LoadingOverlay
            visible={workspaceList.isFetching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <PageTitle text="工作空間" />
        <br />
        <Group>
            <Button onClick={create_open}>創建工作空間</Button>
            <WorkspaceSearch workspaceIds={workspaceList.data?.data.filter(x => x.id).map(x => x.id!) ?? []} />
        </Group>
        <br />
        <Flex gap={10} style={{ flexWrap: "wrap" }}>
            {workspaceList.data?.data.map((d, idx) => {
                // var user = d.users?.find(x => x.userName == auth.data.userName)
                return <WorkspaceListCard key={`WorkspaceCard_${d.id}`} workspace={d} onEnter={() => {
                    if (d.id)
                        navigate({ to: "/workspace/$workspaceId", params: { workspaceId: d.id } })
                }} />
            }
            )}
        </Flex>
        <Modal opened={create_opened} onClose={create_close} title="創建工作空間">
            <EditWorkspaceForm onSubmit={async data => {
                try {
                    await createWorkspace_Mutation.mutateAsync({ params: data })
                    queryClient.invalidateQueries({ queryKey: workspaceList.queryKey })
                    notifications.show({ message: "創建成功" })
                    create_close();
                } catch (e) {
                    notifications.show({ message: "創建失敗", color: "red" })
                }
                console.log(data)
            }} />
        </Modal>
    </Box>
}