import * as R from "remeda";
import { useGetApiCreditKeyList } from "@/API"
import { DataTable } from "mantine-datatable";
import { useQueryClient } from "@tanstack/react-query";
import { Tooltip, Text, Center, Button, Card, Stack, Group, Box } from "@mantine/core";
import { CreditKeyPaymentCard } from "./CreditKeyPaymentCard";
import { PageTitle } from "@/Components/PageTitle";

export function CreditKeyManager() {
    const queryClient = useQueryClient()
    var list = useGetApiCreditKeyList()
    return <Box>
        <PageTitle text="儲值時數管理" />
        <br />
        {/* <Text fw={700} style={{ fontSize: "20px" }} variant="gradient" mb={10} gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
            時數購買
        </Text>
        <Group>
            <CreditKeyPaymentCard productId="480MinUsage" title="輕量方案" subtitle="480分鐘錄音" comment="" priceLabel="LINE Pay 499元" />
            <CreditKeyPaymentCard productId="1200MinUsage" title="中用量方案" subtitle="1200分鐘錄音" comment="(約10場2小時會議)" priceLabel="LINE Pay 1099元" />
            <CreditKeyPaymentCard productId="3000MinUsage" title="高用量方案" subtitle="3000分鐘錄音" comment="(約25場2小時會議)" priceLabel="LINE Pay 2499元" />
        </Group> */}
        {list.isSuccess && list.data.data.length > 0 ? <>
            <DataTable
                withRowBorders={false}
                columns={[
                    {
                        accessor: "keyId", width: 300, title: "Id"
                    },
                    { accessor: "expiryTime", width: 240, title: "到期時間", render: (data) => (new Date(Date.parse(data.expiryTime!)).toLocaleString() + `${Date.parse(data.expiryTime!) - (new Date()).getTime() > 0 ? "" : "(已到期)"}`) },
                    { accessor: "balance", width: 150, title: "剩餘時數", render: (data) => (`${R.round((data.balance ?? 0) / 3600, 2)}(${R.round((data.balance ?? 0) / 60, 1)}分鐘)`) },
                    {
                        accessor: "comment", title: "管理員註記", render: (data) => <Tooltip style={{ whiteSpace: "pre-wrap" }} label={data.comment} multiline><Text variant="text" w={100} fw={300} truncate="end">{data.comment}</Text></Tooltip>
                    }

                ]}
                records={list.data.data.map(x => { return { id: x.keyId, ...x } })}
            />
        </> : "目前尚無時數"}
    </Box>
}
