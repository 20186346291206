import { getGetApiAudioRecordGetQueryKey, usePostApiAudioRecordUploadAudio } from "@/API"
import { Button, FileButton, Flex, Progress } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

export function UploadAudioRecordFileButton(props: { workspaceId: string, audioRecordId: string }) {
    const queryClient = useQueryClient()
    const [uploadAudioFileProgress, setUploadAudioFileProgress] = useState(0)
    const uploadAudioFile_Mutation = usePostApiAudioRecordUploadAudio({
        axios: {
            onUploadProgress: p => {
                if (p.total) setUploadAudioFileProgress(100 * p.loaded / p.total)
            }
        }
    })

    return <Flex justify="center" align="center" h="150px">
        {uploadAudioFileProgress > 0 ? <Progress w="80%" value={uploadAudioFileProgress} /> :
            <FileButton onChange={async f => {
                if (f) {
                    try {
                        await uploadAudioFile_Mutation.mutateAsync({ params: { workspaceId: props.workspaceId, audioRecordId: props.audioRecordId, audioId: f.name }, data: { audioFile: f } })
                        notifications.show({ message: "上傳成功" })
                        queryClient.invalidateQueries({ queryKey: getGetApiAudioRecordGetQueryKey({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId }) })
                    } catch (e) {
                        notifications.show({ message: "上傳失敗" })
                    }
                }
            // }} accept="audio/wav,audio/mp4,audio/mpeg,audio/webm,audio/mp4,video/mp4,video/webm,video/mpeg">
            }} accept="audio/wav">
                {(props) => <Button {...props} variant="gradient" size="xl">上傳音檔</Button>}
            </FileButton>}
    </Flex>
}