import { Text, Box, Breadcrumbs, Flex, Tabs, ActionIcon, Group, useMantineTheme, Button, Paper } from "@mantine/core";
import { AudioRecordItem } from "./AudioRecordItem/AudioRecordItem";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { TextDocumentList } from "./TextDocument/TextDocumentList";
import { Link, useNavigate, useParams, useRouterState } from "@tanstack/react-router";
import { getGetApiAudioRecordGetQueryKey, useGetApiAudioRecordGet, useGetApiWorkspaceGet } from "@/API";
import { useEffect, useMemo, useState } from "react";
import { useAudioRecordStateStore } from "./AudioRecordStateStore";
import { useAuthStore } from "@/Stores/AuthStore";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
import { IconBadgeCc, IconBadgeCcFilled, IconEdit, IconShare } from "@tabler/icons-react";
import { EditAudioRecordNameButton } from "./EditAudioRecordNameButton";
import { useMediaQuery } from "@mantine/hooks";
import { string } from "zod";
import { AudioRecordShareRecordButton } from "../ShareRecord/AudioRecordShareRecordButton";

export function AudioRecordIndex() {
    const navigate = useNavigate()
    const router = useRouterState();
    const theme = useMantineTheme();
    const isMobileLayout = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const auth = useAuthStore()
    const { workspaceId, audioRecordId } = useParams({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId/" })
    const workspaceEditorAuth = UseWorkspaceAuth(workspaceId, "Editor")
    const workspace = useGetApiWorkspaceGet({ id: workspaceId })
    const audioRecord = useGetApiAudioRecordGet({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const audioRecordState = useAudioRecordStateStore()
    const [activeTab, setActiveTab] = useState<string | null>('AudioRecord');

    const isRecording = useMemo(() => {
        return (Date.now() / 1000) - (audioRecord.data?.data.metadata?.recordingHeartbeatTimestamp ?? 0) <= 60
    }, [audioRecord.data?.data.metadata?.recordingHeartbeatTimestamp])
    useEffect(() => {
        if (audioRecordState.targetTime >= 0) {
            setActiveTab("AudioRecord")
        }
    }, [audioRecordState.targetTime])

    useEffect(() => {
        if (audioRecord.status == "error" && audioRecord.error.status == 401) {
            navigate({ to: "/", search: { redirect: router.location.pathname } })
        }
    }, [audioRecord.status])

    return <Flex h="calc(100vh - var(--app-shell-header-height, 0px) - 2*var(--mantine-spacing-lg))" direction="column">
        <Group justify="space-between" mb={10}>
            <Group align="center" gap={5}>
                <Breadcrumbs mb={5} className="no-underline">
                    {auth.data.userName &&
                        <Link to="/workspace">
                            <Text c="gray">
                                工作空間列表
                            </Text>
                        </Link>
                    }
                    {workspace.data &&
                        <Link to="/workspace/$workspaceId" params={{ workspaceId: workspaceId }} >
                            <Text c="gray">
                                {workspace.data?.data.name}
                            </Text>
                        </Link>
                    }
                    <Group gap={0}>
                        <Text fw={500} c="var(--mantine-primary-color-filled)">
                            {audioRecord.data?.data.name}
                        </Text>
                        {workspaceEditorAuth && audioRecord.data?.data && <EditAudioRecordNameButton audioRecord={audioRecord.data?.data}>
                            <ActionIcon variant="subtle" aria-label="Settings">
                                <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                            </ActionIcon>
                        </EditAudioRecordNameButton>
                        }
                    </Group>
                </Breadcrumbs>
            </Group>
            <Group gap={5}>
                {workspace.data?.data && audioRecord.data?.data &&
                    <AudioRecordShareRecordButton workspace={workspace.data?.data} audioRecord={audioRecord.data?.data}>
                        <Button size="compact-sm" leftSection={<IconShare stroke={1.5} size={20} />} radius="xl" variant="white" style={{ boxShadow: "var(--mantine-shadow-xl)" }}>
                            分享
                        </Button>
                    </AudioRecordShareRecordButton>
                }
                {isRecording &&
                    <Button size="compact-sm" leftSection={<IconBadgeCcFilled stroke={1.5} size={20} />} radius="xl" variant="white" style={{ boxShadow: "var(--mantine-shadow-xl)" }} onClick={() => {
                        navigate({
                            to: "/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle", params: {
                                workspaceId: workspaceId, audioRecordId: audioRecordId
                            }
                        })
                    }}>
                        字幕模式
                    </Button>
                }
            </Group>
        </Group>
        <Paper w="100%" h="100%" p={10} radius="md">
            {!isMobileLayout ?
                <Box h="100%">
                    <Allotment >
                        <Box w="100%" h="100%" pr={10}>
                            <AudioRecordItem />
                        </Box>
                        <TextDocumentList />
                    </Allotment>
                </Box> :
                <Box h="100%">
                    <Tabs h="calc(100% - 40px)" variant="outline" defaultValue="AudioRecord" value={activeTab} onChange={setActiveTab}>
                        <Tabs.List mb={5}>
                            <Tabs.Tab value="AudioRecord">錄音</Tabs.Tab>
                            <Tabs.Tab value="TextDocument">文件</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel w="100%" h="100%" value="AudioRecord">
                            <AudioRecordItem />
                        </Tabs.Panel>
                        <Tabs.Panel value="TextDocument">
                            <TextDocumentList />
                        </Tabs.Panel>
                    </Tabs>
                </Box>}
        </Paper>
    </Flex>
}