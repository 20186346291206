import { getGetApiAudioRecordListQueryKey, useGetApiAudioRecordList, useGetApiWorkspaceGet } from "@/API";
import { Box, Group, Text, LoadingOverlay, Pagination, Flex, Button, ActionIcon, Breadcrumbs, Divider, Center, Stack, } from "@mantine/core";
import { Link, useLocation, useNavigate, useParams } from "@tanstack/react-router";
import { useMemo, useState } from "react";
import { AudioRecordListCard } from "./AudioRecordListCard";
import { WorkspaceSearch } from "../Search";
import { CreateAudioRecordButton } from "./CreateAudioRecordButton";
import { IconEdit, IconSettings, IconShare, IconUserShare } from "@tabler/icons-react";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
import { CompareAudioRecordPublicPermission } from "../AudioRecordPublicPermissionText";
import { useAuthStore } from "@/Stores/AuthStore";
import { EditWorkspaceNameButton } from "./EditWorkspaceNameButton";
import { WorkspaceShareRecordButton } from "../ShareRecord/WorkspaceShareRecordButton";
import { RequestWorkspaceAuthButton } from "./RequestWorkspaceAuthButton";
import { PageTitle } from "@/Components/PageTitle";

export function WorkspaceItem() {
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useAuthStore()
    const isLogin = useMemo(() => {
        return !!auth.data.userName;
    }, [auth.data.userName])
    const [activePage, setPage] = useState(1);
    const pageSize = 12;
    const { workspaceId } = useParams({ from: "/workspace/$workspaceId/" })
    const ownerAuth = UseWorkspaceAuth(workspaceId, "Owner")
    const managerAuth = UseWorkspaceAuth(workspaceId, "Manager")
    const viewerAuth = UseWorkspaceAuth(workspaceId, "Viewer")
    const editorAuth = UseWorkspaceAuth(workspaceId, "Editor")
    const workspace = useGetApiWorkspaceGet({ id: workspaceId })
    const publicPermission = useMemo(() => {
        return workspace.data?.data.publicPermission ?? "None"
    }, [workspace.data?.data.publicPermission])
    const audioRecordBaseList = useGetApiAudioRecordList({ workspaceId: workspaceId, skip: (activePage - 1) * pageSize, limit: pageSize }, {
        query: {
            queryKey: getGetApiAudioRecordListQueryKey({ workspaceId: workspaceId, skip: (activePage - 1) * pageSize, limit: pageSize }),
            enabled: viewerAuth || CompareAudioRecordPublicPermission(publicPermission, "View")
        }
    })

    return <Box pos="relative" h="100%">
        <LoadingOverlay
            visible={audioRecordBaseList.isFetching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        <Group mb={10} align="center" gap={10}>
            <Flex align="center">
                <Breadcrumbs className="no-underline">
                    {isLogin &&
                        <Link to="/workspace">
                            <Text c="gray">
                                工作空間列表
                            </Text>
                        </Link>
                    }
                    <Group gap={0}>
                        <Text fw={500} c="var(--mantine-primary-color-filled)">
                            {workspace.data?.data.name}
                        </Text>
                        {ownerAuth && workspace.data?.data && <EditWorkspaceNameButton workspace={workspace.data?.data}>
                            <ActionIcon variant="subtle" aria-label="Settings">
                                <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                            </ActionIcon>
                        </EditWorkspaceNameButton>
                        }
                    </Group>
                </Breadcrumbs>
            </Flex>
            {(managerAuth) &&
                <Button size="compact-sm" leftSection={<IconSettings stroke={1.5} size={20} />} onClick={() => navigate({
                    to: "/workspace/$workspaceId/settings/users", params: {
                        workspaceId: workspaceId
                    }
                })}>
                    <Text size="sm">
                        設定
                    </Text>
                </Button>
            }
            {workspace.data?.data &&
                <WorkspaceShareRecordButton workspace={workspace.data.data}>
                    <Button size="compact-sm" leftSection={<IconShare stroke={1.5} size={20} />}>
                        <Text size="sm">
                            分享
                        </Text>
                    </Button>
                </WorkspaceShareRecordButton>
            }
            {isLogin && !viewerAuth &&
                <RequestWorkspaceAuthButton workspaceId={workspaceId}  >
                    {({ isRequested, isLoading, request }) => (<Button size="compact-sm" loading={isLoading} disabled={isRequested} onClick={request} leftSection={<IconUserShare stroke={1.5} size={20} />}>
                        <Text size="sm">
                            {isRequested ? "已發出請求" : "請求權限"}

                        </Text>
                    </Button>)
                    }
                </RequestWorkspaceAuthButton>
            }
        </Group>
        {workspace.data?.data.name &&
            <PageTitle text={workspace.data?.data.name} />
        }
        {(viewerAuth || CompareAudioRecordPublicPermission(publicPermission, "View")) ? <>
            <Group my={20}>
                <Pagination total={Math.ceil((audioRecordBaseList.data?.data.total ?? 0) / pageSize)} value={activePage} onChange={setPage} siblings={1} defaultValue={10} />
                <WorkspaceSearch workspaceIds={[workspaceId]} />
                {/* <ShareLink url={window.origin + location.pathname} >
                    <Button leftSection={<IconShare />} >分享</Button>
                </ShareLink> */}
                {/* {!viewerAuth && <RequestWorkspaceAuthPage workspaceId={workspaceId} />} */}
                {editorAuth &&
                    <CreateAudioRecordButton workspaceId={workspaceId} >
                        <Button>
                            新增逐字稿
                        </Button>
                    </CreateAudioRecordButton>
                }
            </Group>
            <Flex mt={10} gap={10} style={{ flexWrap: "wrap" }}>
                {workspace.data?.data && audioRecordBaseList.data?.data.results?.map((d, idx) => {
                    return <AudioRecordListCard key={`AudioRecordListCard_${idx}`} workspace={workspace.data?.data} audioRecord={d} onEnter={() => {
                        if (d.id) navigate({
                            to: "/workspace/$workspaceId/audioRecord/$audioRecordId", params: {
                                workspaceId: workspaceId,
                                audioRecordId: d.id
                            }
                        })
                    }} />

                })}
            </Flex ></> : <Center h="80%">
            <Stack>
                <Text c="gray" size="80px" ta="center">
                    無檢視權限
                </Text>
                <Text c="var(--mantine-color-gray-5)" size="30px" ta="center">
                    {
                        isLogin ? <>
                            請至畫面左上請求權限
                        </> : <>
                            請先登入後再嘗試
                        </>
                    }
                </Text>
            </Stack>
        </Center>}
    </Box>
}