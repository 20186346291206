import { AudioRecordPublicPermission, WorkspaceUser } from "@/API";
import { ActionIcon, Avatar, Box, Center, CopyButton, Divider, Group, LoadingOverlay, Modal, Paper, rem, ScrollArea, Select, Stack, Text, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCopy, IconLock, IconWorld } from "@tabler/icons-react";
import { PropsWithChildren, useMemo } from "react";
import { WorkspaceUserRoleToText } from "../WorkspaceUserRoleText";
import { AudioRecordPublicPermissionText } from "../AudioRecordPublicPermissionText";

export type ShareRecordProps = {
    title: string,
    name: string,
    url: string,
    workspaceUsers: WorkspaceUser[],
    publicPermission: AudioRecordPublicPermission,
    publicPermissionTips?: string,
    enableChangePublicPermission?: boolean,
    onPublicPermission?: (value: AudioRecordPublicPermission) => void,
    changePublicPermissionLoading?: boolean
}

export function ShareRecordButton(props: PropsWithChildren & ShareRecordProps) {
    const [opened, { open, close }] = useDisclosure(false);
    const allowClose = useMemo(() => {
        if (props.changePublicPermissionLoading != undefined) return !props.changePublicPermissionLoading;
        return true;
    }, [props.changePublicPermissionLoading])
    return <>
        <Center onClick={open}>
            {props.children}
        </Center>
        <Modal opened={opened} closeOnEscape={allowClose} closeOnClickOutside={allowClose} withCloseButton={allowClose} onClose={close} radius="lg" title={props.title}>
            <ShareRecordUI {...props} />
        </Modal>
    </>
}

export function ShareRecordUI(props: ShareRecordProps) {
    return <>
        <Stack gap={10}>
            <Stack gap={0}>
                <Text >
                    存取權
                </Text>
                <Text size="sm" c="gray">
                    {props.publicPermissionTips}
                </Text>
            </Stack>
            <Group justify="space-between">
                <Group gap={5} style={{ fontSize: "10px" }}>
                    {props.publicPermission == "None" ? <IconLock size="36px" color="var(--mantine-primary-color-filled)" /> : <IconWorld size="36px" color="var(--mantine-primary-color-filled)" />}
                    <Stack gap={0}>
                        <Group>
                            {/* <Text fw={500}>
                                {props.publicPermission == "None" ? "限制" : "公開"}
                            </Text> */}
                            {props.enableChangePublicPermission && props.onPublicPermission ?
                                <Box pos="relative">
                                    <LoadingOverlay visible={props.changePublicPermissionLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} loaderProps={{ type: 'bars', size: "sm" }} />
                                    <Select
                                        size="lg"
                                        m={0}
                                        variant="unstyled"
                                        allowDeselect={false}
                                        onChange={v => {
                                            props.onPublicPermission && props.onPublicPermission(v as AudioRecordPublicPermission)
                                        }}
                                        value={props.publicPermission}
                                        style={{ fontWeight: 700 }}
                                        data={[
                                            { label: AudioRecordPublicPermissionText("None"), value: "None" as AudioRecordPublicPermission },
                                            { label: AudioRecordPublicPermissionText("View"), value: "View" as AudioRecordPublicPermission }
                                        ]}
                                    />
                                </Box> : <Text fw={500}>
                                    {AudioRecordPublicPermissionText(props.publicPermission)}
                                </Text>
                            }
                        </Group>
                        <Group gap={3}>
                            <Text size="sm" c="var(--mantine-color-gray-8)">
                                {props.publicPermission == "None" ? "僅以下使用者可檢視" : "所有人皆可檢視"}
                            </Text>
                            <Text size="sm" c="var(--mantine-primary-color-filled)">
                                {props.name}
                            </Text>
                        </Group>
                    </Stack>
                </Group>
            </Group>
            <Text >
                使用者
            </Text>
            <Group gap={5}>
                {props.workspaceUsers.map(user => {
                    return <Tooltip key={user.userName!} label={`${user.userName}(${WorkspaceUserRoleToText(user.role!)})`}>
                        <Avatar radius="xl" size="md" color="initials" name={user.userName!} />
                    </Tooltip>
                })}
            </Group>
        </Stack>
        <Divider size="sm" my={20} />
        <Stack>
            <Text>
                分享連結
            </Text>
            <Paper px={16} pt={10} bg="var(--mantine-primary-color-light)">
                <Group p={0} gap={0}>
                    <ScrollArea scrollbarSize={10} offsetScrollbars flex={1} scrollbars="x">
                        <Text>
                            {props.url}
                        </Text>
                    </ScrollArea>

                    <CopyButton value={props.url}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? '已複製' : ''} disabled={!copied} withArrow>
                                <ActionIcon size={42} pb={10} variant="transparent" onClick={copy}>
                                    <IconCopy style={{ width: rem(24), height: rem(24) }} />
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                </Group>
            </Paper>
        </Stack>
    </>
}