import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
// import * as Sentry from "@sentry/react";
import { enableMapSet } from 'immer'
enableMapSet()
import { RouterProvider, createRouter } from '@tanstack/react-router'
// import App from './App.tsx'
// import './index.css'
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/tiptap/styles.css';
import 'TiptapStyle.css';
import { MantineProvider, createTheme, virtualColor } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { ModalsProvider } from '@mantine/modals';
import 'mantine-datatable/styles.layer.css';
// Import the generated route tree
import { routeTree } from './routeTree.gen'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuthStore } from './Stores/AuthStore';
import { ConfirmCountDownModal } from './Modals/ConfirmModal';
// if (import.meta.env.MODE != "development") {
//   Sentry.init({
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     environment: import.meta.env.MODE,
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.replayIntegration({
//         maskAllText: false,
//         blockAllMedia: true,
//       })],
//     tracePropagationTargets: [/\/api\//],
//     tracesSampleRate: 1.0,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

// Create a new router instance
const router = createRouter({
  routeTree, context: {
    // auth will initially be undefined
    // We'll be passing down the auth state from within a React component
    authStore: undefined!,
  },
})
// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const theme = createTheme({
  primaryColor: 'primary',
  colors: {
    primary: virtualColor({
      name: 'primary',
      dark: 'cyan',
      light: 'cyan',
    }),
  },
});

const modals = {
  ConfirmCountDown: ConfirmCountDownModal
};
declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}
const queryClient = new QueryClient()

function InnerApp() {
  const auth = useAuthStore()
  return <RouterProvider router={router} context={{ authStore: auth }} />
}
ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <MantineProvider theme={theme}>
    <Notifications />
    <ModalsProvider modals={modals}>
      <QueryClientProvider client={queryClient} >
        <InnerApp />
      </QueryClientProvider>
    </ModalsProvider>
  </MantineProvider>
  // </React.StrictMode>,
)
console.log(`Build:${BUILD_HASH}(${new Date(BUILD_DATE).toLocaleString()})`)