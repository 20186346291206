import * as R from "remeda"
import * as Y from 'yjs'
import { AudioRecord, postApiAudioRecordTextDocumentCreate, postApiSummarizationTemplateApply, postApiTextSummarization, postApiTextTextKeypoints, putApiAudioRecordTextDocumentUpdate } from "@/API"
import { SplitAudioRecordEntries } from "./utils"
import { AxiosError } from "axios"
import { Editor } from "@tiptap/core"
import { byteArrayToString } from "../SignalrProvider/encodingUtils";

export async function CreateSummaryTemplate(editor: Editor, yDoc: Y.Doc, templateName: string, record: AudioRecord, languageCode: string, onProgress?: (progress: number) => void) {
    var recordEntries = R.sortBy(record.recordEntries ?? [], x => x.time ?? 0)
    var splitData = SplitAudioRecordEntries(recordEntries, 10000, 2048)
    var keypoints = ""
    editor?.commands.setContent("")
    try {
        for (var i = 0; i < splitData.length; i++) {
            var keypoints_result = await postApiTextTextKeypoints(splitData[i].text, { language: languageCode }, {
                headers: { "Content-Type": "application/json" }
            })
            onProgress?.((i + 1) / (splitData.length + 1))
            keypoints += keypoints_result.data + "\n"
        }
    }
    catch (e) {
        const error = e as AxiosError
        var message = ""
        if (typeof error.response?.data == "string")
            message = error.response?.data as string
        throw message
    }
    var result = await postApiSummarizationTemplateApply(keypoints, { languageCode: languageCode, templateName: templateName }, {
        headers: { "Content-Type": "application/json" }
    })
    // editor?.commands.insertContentAt(editor?.state.doc.content.size, result.data)
    editor?.commands.setContent(result.data)

    var createResult = await postApiAudioRecordTextDocumentCreate({ name: "摘要", workspaceId: record.workspaceId ?? "", audioRecordId: record.id ?? "" })
    onProgress?.(1)
    var doc = R.clone(createResult.data)
    doc.textType = "Markdown"
    doc.text = editor?.storage.markdown.getMarkdown();
    doc.rawTextType = "Yjs"
    doc.rawText = byteArrayToString(Y.encodeStateAsUpdate(yDoc))

    var update = await putApiAudioRecordTextDocumentUpdate(doc, { workspaceId: record.workspaceId ?? "" })
    return doc
}

export async function CreateSummary(editor: Editor, yDoc: Y.Doc, record: AudioRecord, languageCode: string, onProgress?: (progress: number) => void) {
    var recordEntries = R.sortBy(record.recordEntries ?? [], x => x.time ?? 0)
    var splitData = SplitAudioRecordEntries(recordEntries)

    editor?.commands.setContent("")
    editor?.commands.insertContent(`## ${record.name} 摘要`)
    // console.log(editor?.state.doc.content.size)

    try {
        for (var i = 0; i < splitData.length; i++) {
            var summary_result = await postApiTextSummarization(splitData[i].text, { headers: { "Content-Type": "application/json" } })
            onProgress?.((i + 1) / (splitData.length + 1))
            editor?.commands.insertContentAt(editor?.state.doc.content.size, `<AudioRecordTime time=${splitData[i].time + 0.1} />`)
            editor?.commands.insertContentAt(editor?.state.doc.content.size, summary_result.data)
        }
    }
    catch (e) {
        const error = e as AxiosError
        var message = ""
        if (typeof error.response?.data == "string")
            message = error.response?.data as string
        throw message
    }
    var createResult = await postApiAudioRecordTextDocumentCreate({ name: "摘要", workspaceId: record.workspaceId ?? "", audioRecordId: record.id ?? "" })
    onProgress?.(1)
    var doc = R.clone(createResult.data)
    doc.textType = "Markdown"
    doc.text = editor?.storage.markdown.getMarkdown();
    doc.rawTextType = "Yjs"
    doc.rawText = byteArrayToString(Y.encodeStateAsUpdate(yDoc))
    var update = await putApiAudioRecordTextDocumentUpdate(doc, { workspaceId: record.workspaceId ?? "" })
    return doc
}