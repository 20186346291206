import { AudioRecordPublicPermission, WorkspaceUserRole } from "@/API";
import { Badge, BadgeProps } from "@mantine/core";
import { useCallback } from "react";

export function AudioRecordPublicPermissionBadge({ permission: permission, badgeProps }: { permission: AudioRecordPublicPermission, badgeProps?: BadgeProps }) {
    const badge = useCallback(() => {
        switch (permission) {
            case 'None': return <Badge color="grape" {...badgeProps}>{AudioRecordPublicPermissionText(permission)}</Badge>
            case 'View': return <Badge color="#00BF98" {...badgeProps}>{AudioRecordPublicPermissionText(permission)}</Badge>
            default: return <Badge color="gray" {...badgeProps}>{AudioRecordPublicPermissionText(permission)}</Badge>;
        }
    }, [permission]);
    return <>{badge()}</>
}
export function AudioRecordPublicPermissionText(permission: AudioRecordPublicPermission) {
    switch (permission) {
        case 'None': return "私人"
        case 'View': return "公開檢視"
        default: return "無效"
    }
}
export function CompareAudioRecordPublicPermission(permission: AudioRecordPublicPermission, targetPermission: AudioRecordPublicPermission) {
    var p = AudioRecordPublicPermissionToLevel(permission)
    var target = AudioRecordPublicPermissionToLevel(targetPermission)
    return p >= target
}
export function GetAudioRecordPublicPermission(permission: AudioRecordPublicPermission, minPermission: AudioRecordPublicPermission) {
    var p = AudioRecordPublicPermissionToLevel(permission)
    var minP = AudioRecordPublicPermissionToLevel(minPermission)
    if (minP > p) return minPermission
    else return permission
}
export function AudioRecordPublicPermissionToLevel(permission: AudioRecordPublicPermission) {
    switch (permission) {
        case 'None': return 0
        case 'View': return 1
        default: return -1
    }
}