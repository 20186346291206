import { getGetApiWorkspaceGetQueryKey, getGetApiWorkspaceListQueryKey, usePutApiWorkspaceUpdatePublicPermission, Workspace } from "@/API";
import { PropsWithChildren, useState } from "react";
import { ShareRecordButton } from "./ShareRecordUI";
import { GetWorkspaceUrl } from "@/APIURL";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";

export function WorkspaceShareRecordButton({ workspace, children }: { workspace: Workspace } & PropsWithChildren) {
    const queryClient = useQueryClient()
    const ownerAuth = UseWorkspaceAuth(workspace.id ?? "", "Owner")
    const [updateLoading, setUpdateLoading] = useState(false)
    const editPermission_Mutation = usePutApiWorkspaceUpdatePublicPermission()

    return <>
        <ShareRecordButton title="分享工作空間" url={window.origin + GetWorkspaceUrl(workspace.id!)} name={workspace.name!} publicPermission={workspace.publicPermission ?? "None"} workspaceUsers={workspace.users ?? []} enableChangePublicPermission={ownerAuth} changePublicPermissionLoading={updateLoading} onPublicPermission={async p => {
            setUpdateLoading(true)
            try {
                try {
                    await editPermission_Mutation.mutateAsync({ params: { id: workspace.id!, permission: p } })
                    notifications.show({ message: "修改成功" })
                    queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                    queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceGetQueryKey({ id: workspace.id! }) })
                } catch (e) {
                    notifications.show({ message: "修改失敗", color: "red" })
                }
            }
            catch (e) {

            }
            setUpdateLoading(false)
        }}>
            {children}
        </ShareRecordButton>
    </>
}