import * as R from "remeda"
import dayjs from "dayjs";
import { postApiSttUsageLogSearch, usePostApiSttUsageLogSearch } from '@/API';
import { get_random_hue_color } from '@/RandomColor';
import { Box, Text } from "@mantine/core"
import { BarChart, BarChartSeries } from '@mantine/charts';
import { useEffect, useState } from 'react';
import { PageTitle } from "@/Components/PageTitle";
export function SttUsageIndex() {
    var [data, setData] = useState<Record<string, any>[]>()
    var [series, setSeries] = useState<BarChartSeries[]>()
    useEffect(() => {
        postApiSttUsageLogSearch({
            startTime: dayjs().add(-1, "month").toISOString(),
            endTime: dayjs().toISOString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }).then(logs => {
            var _series: Set<string> = new Set(["unkonwn"]);
            var result: any = {}
            result = logs.data.map(x => {
                var d = { time: dayjs(x.time!).toDate().toLocaleDateString() }
                x.usages?.forEach(usage => {
                    if (usage.usage) {
                        d = {
                            ...d,
                            [usage.appKey ?? "unkonwn"]: R.round(usage.usage / 60, 1)
                        }
                        if (usage.appKey)
                            _series.add(usage.appKey)
                    }
                })
                return d
            })
            setData(result)
            setSeries([..._series].map((key) => {
                return {
                    name: key,
                    color: get_random_hue_color(50, 60)
                }
            }))
        })
    }, [])
    return <Box>
        <PageTitle text="使用量查詢" />
        <br />
        <Text m="md">
            一個月內使用狀況
        </Text>
        <Box m="md">
            {data && data.length > 0 && series ?
                <BarChart
                    h={300}
                    data={data}
                    dataKey="time"
                    type="stacked"
                    series={series}
                    unit="分鐘"
                /> : <>
                    目前尚無資料
                </>}
        </Box>
    </Box>
}