import * as R from "remeda";
import { WorkspaceUserRole } from "@/API";
import { Button, Group, Modal, Select, TextInput, Text } from "@mantine/core";
import { PropsWithChildren, useMemo, useState } from "react";
import { WorkspaceUserRoleToText } from "@/Workspaces/WorkspaceUserRoleText";
import { useDisclosure } from "@mantine/hooks";

export function WorkspaceAddUserButton(props: { title?: string, workspaceName: string, options: WorkspaceUserRole[], onConfirm: (userNames: string[], role: WorkspaceUserRole) => void } & PropsWithChildren) {
    const [opened, { open, close }] = useDisclosure(false);

    return <>
        <span onClick={open}>
            {props.children}
        </span>
        <Modal opened={opened} onClose={close} radius="lg" title={props.title} centered>
            <WorkspaceAddUserForm workspaceName={props.workspaceName} options={props.options} onConfirm={(v, role) => {
                props.onConfirm(v, role);
                close();
            }} />
        </Modal>
    </>
}
export function WorkspaceAddUserForm(props: { workspaceName: string, options: WorkspaceUserRole[], onConfirm: (userNames: string[], role: WorkspaceUserRole) => void }) {
    const [userNames, setUserNames] = useState('');
    const results = useMemo(() => {
        return R.split(userNames.trim(), /[\s,]/u).filter(x => x.trim() != "");
    }, [userNames])
    const [role, setRole] = useState<WorkspaceUserRole>('Viewer');
    return <>
        <Group gap={0}>
            <Text>
                工作空間名稱：
            </Text>
            <Text c="var(--mantine-primary-color-filled)">
                {props.workspaceName}
            </Text>
        </Group>
        <br />
        <Group gap={0}>
            <Text>
                使用者帳號（可用半形逗號[ , ]分隔多筆資料）
            </Text>
            <TextInput
                placeholder="輸入使用者帳號"
                value={userNames}
                onChange={(event) => setUserNames(event.currentTarget.value)}
            />
        </Group>
        <Text c="gray" size="sm">
            已輸入 {results.length} 筆資料
        </Text>
        <Group gap={10} mt={10}>
            <Text >
                權限
            </Text>
            <Select
                variant="filled"
                size="sm"
                w={100}
                allowDeselect={false}
                data={props.options.map(x => {
                    return { value: x, label: WorkspaceUserRoleToText(x) }
                })}
                value={role}
                onChange={x => { setRole(x as WorkspaceUserRole) }}
            />
        </Group>
        <Group justify="flex-end" mt="md">
            <Button w={120} onClick={() => {
                props.onConfirm(results, role)
            }}>新增</Button>
        </Group>
    </>
}