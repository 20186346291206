import { useGetApiWorkspaceGet } from "@/API";
import { NavigationButton } from "@/Components/NavigationButton";
import { useAuthStore } from "@/Stores/AuthStore";
import { ActionIcon, Box, Breadcrumbs, Flex, Paper, Stack, Text } from "@mantine/core";
import { IconCategory, IconInfoCircle, IconUser, IconUsersPlus } from "@tabler/icons-react";
import { Link, Outlet, useParams } from "@tanstack/react-router";

function UserNavigation() {
    return <>
        <NavigationButton match={{ to: "/workspace/$workspaceId/settings/users", fuzzy: true }} ><IconUser style={{ marginRight: "10px" }} />使用者</NavigationButton>
        <NavigationButton match={{ to: "/workspace/$workspaceId/settings/pendingUsers", fuzzy: true }} ><IconUsersPlus style={{ marginRight: "10px" }} />待處理使用者</NavigationButton>
        <NavigationButton match={{ to: "/workspace/$workspaceId/settings/info", fuzzy: true }} ><IconInfoCircle style={{ marginRight: "10px" }} />資訊</NavigationButton>
    </>
}

function Navigation() {
    const auth = useAuthStore()
    return <>
        {/* <Button variant="subtle" onClick={() => { navigate({ to: "/" }); }}>首頁</Button> */}
        <Stack w="150px" gap={5}>
            <UserNavigation />
        </Stack>
    </>
}

export function SettingsIndex() {
    const { workspaceId } = useParams({ from: "/workspace/$workspaceId/settings" })
    const workspace = useGetApiWorkspaceGet({ id: workspaceId })
    return <Flex direction="column" w="100%" h="100%" p="0" >
        <Breadcrumbs className="no-underline" mb={10}>
            <Link to="/workspace">
                <Text c="gray">
                    工作空間列表
                </Text>
            </Link>
            <Link to="/workspace/$workspaceId" params={{ workspaceId: workspaceId }}>
                <Text c="gray">
                    {workspace.data?.data.name}
                </Text>
            </Link>
            <Text fw={500} c="var(--mantine-primary-color-filled)">
                設定
            </Text>
        </Breadcrumbs>
        <Paper radius="md" pt="xs">
            <Flex direction="row" w="100%" h="100%" p="0" >
                <Navigation />
                <Box style={{ flexGrow: 1 }} h="100%">
                    <Outlet />
                </Box>
            </Flex>
        </Paper>
    </Flex>
}