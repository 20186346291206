import { useDeleteApiAudioRecordJobDeleteTranscriptionJob, useDeleteApiAudioRecordJobDeleteTranslationJob, useGetApiAudioRecordJobGetTranscriptionJob, useGetApiAudioRecordJobGetTranslationJob } from "@/API"
import { languageCodeData } from "@/Helper/languageCodeData"
import { SecondToString } from "@/Helper/string"
import { Alert, Button, Progress, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { notifications } from "@mantine/notifications"
import { IconInfoCircle } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import * as R from "remeda";
import { string } from "zod"

export function AudioTranslationJobInfo(props: { workspaceId: string, audioRecordId: string, onDelete?: () => void }) {
    const queryClient = useQueryClient()
    const info = useGetApiAudioRecordJobGetTranslationJob({ workspaceId: props.workspaceId, audioRecordId: props.audioRecordId })
    const delete_Mutation = useDeleteApiAudioRecordJobDeleteTranslationJob()
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        if (info.data?.data.totalCount && info.data?.data.processedCount)
            setProgress(R.round(0)(100 * R.clamp(info.data.data.processedCount / info.data.data.totalCount, { min: 0, max: 100 })))
    }, [info.data?.data.processedCount])
    return <>
        <Button color="red" onClick={() => {
            modals.openConfirmModal({
                title: '取消翻譯',
                children: (
                    <Text size="sm">
                        是否要取消翻譯?
                    </Text>
                ),
                labels: { confirm: '確定', cancel: '取消' },
                confirmProps: { color: "red" },
                onConfirm: async () => {
                    try {
                        await delete_Mutation.mutateAsync({
                            params: { workspaceId: props.workspaceId, audioRecordId: props.audioRecordId }
                        })
                        queryClient.invalidateQueries({ queryKey: info.queryKey })
                        notifications.show({ message: "取消成功" })
                        props.onDelete?.()
                    } catch (e) {
                        notifications.show({ message: "取消失敗", color: "red" })
                    }
                },
            });
        }}>
            取消翻譯
        </Button>
        {info.data?.data.createdTime &&
            <Text>
                開始時間:{dayjs(info.data?.data.createdTime).toDate().toLocaleString()}
            </Text>}
        {info.data?.data.updatedTime &&
            <Text>
                更新時間:{dayjs(info.data?.data.updatedTime).toDate().toLocaleString()}
            </Text>}
        <Text>
            正在翻譯:{info.data?.data.translatedLanguages?.filter(x => !!x).map(x => languageCodeData[x]?.name).join(' ').trim()}
        </Text>
        <Text>
            {info.data?.data.processedCount} / {(info.data?.data.totalCount ?? 0)}
        </Text>
        <Progress.Root size="xl">
            <Progress.Section value={progress}>
                <Progress.Label>{progress}%</Progress.Label>
            </Progress.Section>
        </Progress.Root>
        {info.data?.data.message &&
            <Alert mt={10} variant="light" title="訊息" icon={<IconInfoCircle />}>
                {info.data?.data.message}
            </Alert>}
    </>
}