import * as React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { AudioRecordSubtitle } from '@/Workspaces/AudioRecord/AudioRecordSubtitle'

export const Route = createFileRoute(
  '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return <AudioRecordSubtitle />
}
