import { SecondToString } from "@/Helper/string"
import { ActionIcon, Box, Flex, Group, Slider, Stack, Text, Transition, useMantineTheme } from "@mantine/core"
import { useElementSize, useHover, useMediaQuery } from "@mantine/hooks"
import { IconPlayerPauseFilled, IconPlayerPlayFilled, IconPlayerTrackNextFilled, IconPlayerTrackPrevFilled, IconVolume, IconVolume2, IconVolume3 } from "@tabler/icons-react"
import { forwardRef, RefObject, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import ReactPlayer from "react-player"

export type AudioPlayerHandle = {
    Seek: (second: number) => void;
    SetPlaying: (value: boolean) => void;
};
export type AudioPlayerProps = { url: string, onSecondProgress?: (second: number) => void, onReady?: () => void };
export const AudioPlayer = forwardRef<AudioPlayerHandle, AudioPlayerProps>(function AudioPlayer(props, ref) {
    const theme = useMantineTheme();
    const { ref: playerSizeRef, width, height } = useElementSize();
    const wideLayout = useMemo(() => {
        return width >= 600
    }, [width])
    const activeColor = theme.colors[theme.primaryColor][5]
    const player = useRef<ReactPlayer>(null)
    const [duration, setDuration] = useState(-1)
    const [progress, setProgress] = useState(0)
    const [previousVolume, setPreviousVolume] = useState(1)
    const [volume, setVolume] = useState(1)
    const { hovered: volumeHovered, ref: volumeRef } = useHover();
    const [playing, setPlaying] = useState(false)
    const [disablePlayer, setDisablePlayer] = useState(false)
    useImperativeHandle(ref, () => {
        return {
            Seek(second: number) {
                var frac = second / duration
                setProgress(frac)
                player.current?.seekTo(second, "seconds")
                props.onSecondProgress?.(second)
            },
            SetPlaying(value: boolean) {
                setPlaying(value)
            }
        };
    }, [player.current, duration]);
    return <div ref={playerSizeRef}>
        {!disablePlayer &&
            <ReactPlayer playing={playing} volume={volume} ref={player} height={0} url={props.url} onError={e => { setDisablePlayer(true); }} onProgress={() => {
                setProgress((player.current?.getCurrentTime() ?? 0) / duration)
                if (player.current?.getCurrentTime()) props.onSecondProgress?.(player.current?.getCurrentTime())
            }} onDuration={(v) => { setDuration(v) }} onReady={props.onReady} />
        }
        <Box hidden={duration <= 0}>
            <Stack w="100%" gap={0} align="center">
                <Slider w="100%" value={progress * 100}
                    label={value => SecondToString(duration * value / 100)}
                    onChange={v => {
                        var frac = v / 100
                        player.current?.seekTo(frac, "fraction")
                        setProgress(frac)
                        if (player.current?.getCurrentTime()) props.onSecondProgress?.(player.current?.getCurrentTime())
                    }} />
                <Flex>
                    <Text c={activeColor}>{SecondToString(player.current?.getCurrentTime() ?? 0)}</Text><Text c="gray">/{SecondToString(duration)}</Text>
                </Flex>
                <Flex w="100%" justify="center" align="center" m={10} >
                    <Group gap={0}>
                        <Stack align="center" gap={0}>
                            <ActionIcon variant="subtle" radius="xl" size={50}>
                                <IconPlayerTrackPrevFilled style={{ width: '70%', height: '70%' }} onClick={() => {
                                    if (player.current) {
                                        var target = player.current.getCurrentTime() - 3;
                                        player.current?.seekTo(target, "seconds")
                                        setProgress(target / duration)
                                        if (player.current?.getCurrentTime()) props.onSecondProgress?.(player.current?.getCurrentTime())
                                    }
                                }} />
                            </ActionIcon>
                            <Text c="dark">
                                3秒
                            </Text>
                        </Stack>
                        <ActionIcon variant="filled" radius="100%" size={70} mx={20} onClick={() => {
                            setPlaying(x => !x)
                        }}>
                            {playing ? <IconPlayerPauseFilled style={{ width: '70%', height: '70%' }} />
                                : <IconPlayerPlayFilled style={{ width: '70%', height: '70%' }} />}
                        </ActionIcon>
                        <Stack align="center" gap={0}>
                            <ActionIcon variant="subtle" radius="xl" size={50} onClick={() => {
                                if (player.current) {
                                    var target = player.current.getCurrentTime() + 3;
                                    player.current?.seekTo(target, "seconds")
                                    setProgress(target / duration)
                                    if (player.current?.getCurrentTime()) props.onSecondProgress?.(player.current?.getCurrentTime())
                                }
                            }}>
                                <IconPlayerTrackNextFilled style={{ width: '70%', height: '70%' }} />
                            </ActionIcon>
                            <Text c="dark">
                                3秒
                            </Text>
                        </Stack>
                    </Group>
                    {wideLayout &&
                        <Group ref={volumeRef as RefObject<HTMLDivElement>} pos="absolute" right={20}>
                            <Transition
                                mounted={volumeHovered}
                                transition="fade"
                                duration={200}
                                timingFunction="ease"
                            >
                                {(styles) =>
                                    <Slider style={styles} size="sm" variant="" w={100} value={volume} onChange={x => {
                                        setVolume(x)
                                        setPreviousVolume(x)
                                    }} min={0} step={0.1} max={1} />
                                }

                            </Transition>
                            <ActionIcon variant="transparent" size="lg" onClick={() => {
                                if (volume != 0)
                                    setVolume(0)
                                else
                                    setVolume(previousVolume)
                            }}>
                                {volume == 0 ? <IconVolume3 style={{ width: '80%', height: '80%' }} stroke={1.5} /> : volume <= 0.5 ? <IconVolume2 style={{ width: '80%', height: '80%' }} stroke={1.5} /> : <IconVolume style={{ width: '80%', height: '80%' }} stroke={1.5} />}
                            </ActionIcon>
                        </Group>
                    }
                </Flex>
            </Stack>
        </Box>
    </div>
})