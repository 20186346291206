import { Badge, BadgeProps } from "@mantine/core";
import { HubConnectionState } from "@microsoft/signalr";

export function SignalrStateBadge({ state, ...props }: { state: HubConnectionState } & BadgeProps) {
    const badge = () => {
        switch (state) {
            case HubConnectionState.Connected: return <Badge size="lg" color="green" {...props}>已連線</Badge>;
            case HubConnectionState.Connecting: return <Badge size="lg" color="yellow" {...props}>連線中...</Badge>;
            case HubConnectionState.Disconnected: return <Badge size="lg" color="red" {...props}>連線中斷</Badge>;
            default: return <Badge size="lg" color="red" {...props}>連線中斷</Badge>
        }
    }
    return <>
        {badge()}
    </>
}