import * as R from "remeda";
import { useGetApiPeriodCreditKeyList } from "@/API"
import { DataTable } from "mantine-datatable";
import { useQueryClient } from "@tanstack/react-query";
import { Tooltip, Text, Box } from "@mantine/core";
import { PageTitle } from "@/Components/PageTitle";

export function PeriodCreditKeyManager() {
    const queryClient = useQueryClient()
    var list = useGetApiPeriodCreditKeyList()
    return <Box>
        <PageTitle text="訂閱管理" />
        <br />
        {list.isSuccess && list.data.data.length > 0 ? <>
            <DataTable
                withRowBorders={false}
                columns={[
                    {
                        accessor: "keyId", width: 300, title: "Id"
                    },
                    { accessor: "startTime", width: 100, title: "開始時間", render: (data) => (new Date(Date.parse(data.startTime!)).toLocaleDateString()) },
                    { accessor: "expiryTime", width: 240, title: "到期時間", render: (data) => (new Date(Date.parse(data.expiryTime!)).toLocaleString() + `${Date.parse(data.expiryTime!) - (new Date()).getTime() > 0 ? "" : "(已到期)"}`) },
                    { accessor: "balance", width: 150, title: "剩餘時數", render: (data) => (`${R.round((data.balance ?? 0) / 3600, 2)}(${R.round((data.balance ?? 0) / 60, 1)}秒)`) },
                    {
                        accessor: "comment", title: "管理員註記", render: (data) => <Tooltip style={{ whiteSpace: "pre-wrap" }} label={data.comment} multiline><Text variant="text" w={100} fw={300} truncate="end">{data.comment}</Text></Tooltip>
                    }

                ]}
                records={list.data.data.map(x => { return { id: x.keyId, ...x } })}
            />
        </> : "目前尚無訂閱"}
    </Box>
}
