import { stat } from 'fs'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

type AudioRecordSubtitleSetting = {
    maxTextLine: number,
    backgroundColor: string,
    showText: boolean,
    textColor: string,
    translateTextColor: string,
    fontSize: number,
    translateFontSize: number,
}
const initialState: AudioRecordSubtitleSetting = {
    maxTextLine: 3,
    backgroundColor: "#ffffff",
    showText: true,
    textColor: "#000000",
    translateTextColor: "#868e96",
    fontSize: 32,
    translateFontSize: 24,
}
export interface AudioRecordSubtitleSettingStore {
    setting: AudioRecordSubtitleSetting
    Update: (updateAction: (setting: AudioRecordSubtitleSetting) => void) => void
    Reset: () => void
}
export const useAudioRecordSubtitleSettingStore = create<AudioRecordSubtitleSettingStore>()(
    devtools(
        persist(
            immer((set) => ({
                setting: { ...initialState },
                Update: (updateAction: (setting: AudioRecordSubtitleSetting) => void) =>
                    set((state) => {
                        updateAction(state.setting)
                    }),
                Reset: () => {
                    set((state) => {
                        state.setting = { ...initialState }
                    })
                }
            })), {
            name: "AudioRecordSubtitleSettingStore",
        })
    ),
)