import { Text, Divider, Group, Center, Loader } from "@mantine/core";

export function RealtimeTextItem(props: { text: string }) {
    return <Group pos="relative" gap={0} mt={10} ml="2.5px">
        <Divider orientation="vertical" size="3px" pos="relative" left={3} color="#40c057" />
        <Text
            component="div"
            display="inline"
            flex={1}
            style={{
                marginLeft: '15px',
                marginBottom: '0px',
                color: "#40c057",
                whiteSpace: "pre-wrap"
            }}
            size="sm"
        >
            {props.text}
            <div style={{ display: "inline-block" }}>
                <Center ml={5}>
                    <Loader size="xs" color="#40c057" type="dots" />
                </Center>
            </div>
        </Text>
    </Group>
}