import { AudioRecordEntry } from "@/API"

type SplitAudioRecordEntriesResult = {
    time: number,
    text: string,
}

export function SplitAudioRecordEntries(entries: AudioRecordEntry[], intervalThreshold = 5, maxTextCount = 1000) {
    if (entries.length == 0)
        return []
    var results: SplitAudioRecordEntriesResult[] = []
    var target: SplitAudioRecordEntriesResult = {
        time: entries[0].time!,
        text: ""
    }
    var lastTime = entries[0].time!
    for (var i = 0; i < entries.length; i++) {
        const entry = entries[i]
        if (entry.time! - lastTime > intervalThreshold || (target.text + entry.text).length > maxTextCount) {
            results.push(target)
            target = {
                time: entry.time!,
                text: ""
            }
        }
        lastTime = entry.time! + entry.duration!
        target.text += entry.text + "\n"
    }
    if (target.text) {
        results.push(target)
    }
    return results
}