import * as R from "remeda"
import { useDeleteApiWorkspaceUserDeleteUser, useGetApiWorkspaceGet, useGetApiWorkspaceUserListUsers, usePostApiWorkspaceUserAddUsers, usePutApiWorkspaceUserSetUserOwnerRole, usePutApiWorkspaceUserSetUserRole, WorkspaceUserRole } from "@/API"
import { ActionIcon, Text, Box, Group, Pagination, Flex, Button } from "@mantine/core"
import { useNavigate, useParams } from "@tanstack/react-router"
import { useMemo, useState } from "react"
import { DataTable } from "mantine-datatable"
import { modals } from "@mantine/modals"
import { IconEdit, IconSwitchHorizontal, IconTrash, IconUserCircle } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { notifications } from "@mantine/notifications"
import { useAuthStore } from "@/Stores/AuthStore"
import { WorkspaceUserRoleBadge } from "../WorkspaceUserRoleText"
import { SelectWorkspaceUserRoleButton } from "./components/WorkspaceUserRole"
import { WorkspaceAddUserButton } from "./components/WorkspaceAddUser"

export function WorkspaceUsersSettings(props: {}) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const auth = useAuthStore()
    const { workspaceId } = useParams({ from: "/workspace/$workspaceId/settings/users" })
    const workspace = useGetApiWorkspaceGet({ id: workspaceId })
    const users = useGetApiWorkspaceUserListUsers({ workspaceId: workspaceId })
    const addUsers_Mutation = usePostApiWorkspaceUserAddUsers()
    const SetUserRole_Mutation = usePutApiWorkspaceUserSetUserRole()
    const SetUserOwnerRole_Mutation = usePutApiWorkspaceUserSetUserOwnerRole()
    const DeleteUser_Mutation = useDeleteApiWorkspaceUserDeleteUser()
    const [activePage, setPage] = useState(1);
    const pageCount = 10
    const user = useMemo(() => {
        return users.data?.data.find(x => x.userName == auth.data.userName)
    }, [users])

    return <Box>
        {users.isSuccess && <>
            <Group p={10}>
                <Pagination value={activePage} onChange={setPage} total={R.ceil(((users.data?.data.length ?? 0) / pageCount), 0)} />
                <WorkspaceAddUserButton title="新增成員" workspaceName={workspace.data?.data.name ?? ""} options={["Viewer", "Editor", "Manager"]} onConfirm={async (userNames, role) => {
                    try {
                        var result = await addUsers_Mutation.mutateAsync({
                            params: { workspaceId: workspaceId }, data: userNames.map(x => {
                                return {
                                    userName: x,
                                    role: role
                                }
                            })
                        })
                        queryClient.invalidateQueries({ queryKey: users.queryKey })
                        notifications.show({ message: `已新增${result.data.length}位使用者` })
                        var failUsers = userNames.filter(x => !result.data.includes(x))
                        failUsers.forEach(failUser => {
                            notifications.show({ message: `${failUser} 新增失敗`, color: "red" })
                        })
                    } catch (e) {
                        notifications.show({ message: `新增使用者失敗`, color: "red" })
                    }
                }} >
                    <Button size="compact-sm">
                        新增成員
                    </Button>
                </WorkspaceAddUserButton>
            </Group>
            <DataTable
                minHeight={users.data.data.length > 0 ? 0 : 150}
                noRecordsText="沒有使用者"
                withRowBorders={false}
                columns={[
                    {
                        accessor: 'userName', title: <Text size="sm">使用者名稱</Text>, render: (data) =>
                            <Text mr={5}>{data.userName}</Text>
                    },
                    {
                        accessor: 'role', title: <Text size="sm">權限</Text>, render: (data) =>
                            <Flex direction="row" align="center">
                                <WorkspaceUserRoleBadge role={data.role!} />
                            </Flex>
                    },
                    {
                        accessor: 'actions',
                        title: <Text size="sm" mr={6}>操作</Text>,
                        textAlign: 'right',
                        render: (data) => (
                            <Group key={`op_${data.userName}`} gap={4} justify="right" wrap="nowrap">
                                {(user?.userName != data.userName && (user?.role == "Owner" || data.role != "Owner")) &&
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                    >
                                        <SelectWorkspaceUserRoleButton title="選擇使用者角色" initValue={data.role!} options={["Viewer", "Editor", "Manager"]} onConfirm={async v => {
                                            if (data.userName) {
                                                try {
                                                    await SetUserRole_Mutation.mutateAsync({
                                                        params: {
                                                            workspaceId: workspaceId, userName: data.userName, role: v
                                                        }
                                                    })
                                                    queryClient.invalidateQueries({ queryKey: users.queryKey })
                                                    notifications.show({ message: "設定成功" })
                                                } catch (e) {
                                                    notifications.show({ message: "設定失敗", color: "red" })
                                                }
                                            }
                                        }}>
                                            <IconUserCircle size={16} />
                                        </SelectWorkspaceUserRoleButton >
                                    </ActionIcon>
                                }
                                {(user?.userName != data.userName && user?.role == "Owner") &&
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                        c="green"
                                        onClick={() => {
                                            modals.openContextModal({
                                                modal: "ConfirmCountDown",
                                                title: '轉移擁有者',
                                                innerProps: {
                                                    countDownSecond: 5,
                                                    children: (
                                                        <Text size="sm">
                                                            是否要轉移擁有者給"{data.userName}"?
                                                        </Text>
                                                    ),
                                                    labels: {
                                                        confirm: '確定', cancel: '取消'
                                                    },
                                                    confirmProps: { color: 'green' },
                                                    onConfirm: async () => {
                                                        try {
                                                            if (data.userName) {
                                                                await SetUserOwnerRole_Mutation.mutateAsync({ params: { workspaceId: workspaceId, userName: data.userName } })
                                                                queryClient.invalidateQueries({ queryKey: users.queryKey })
                                                                notifications.show({ message: "轉移成功" })
                                                            }
                                                        } catch (e) {
                                                            notifications.show({ message: "轉移失敗", color: "red" })
                                                        }
                                                    },
                                                },

                                            });
                                        }}
                                    >
                                        <IconSwitchHorizontal size={16} />
                                    </ActionIcon>
                                }
                                {auth.data.userName != data.userName && (user?.role == "Owner" || data.role != "Owner") &&
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                        color="red"
                                        onClick={() => {
                                            modals.openContextModal({
                                                modal: "ConfirmCountDown",
                                                title: '刪除使用者',
                                                innerProps: {
                                                    countDownSecond: 0,
                                                    children: (
                                                        <Text size="sm">
                                                            是否要刪除使用者"{data.userName}"?
                                                        </Text>
                                                    ),
                                                    labels: {
                                                        confirm: '刪除', cancel: '取消'
                                                    },
                                                    confirmProps: { color: 'red' },
                                                    onConfirm: async () => {
                                                        try {
                                                            if (data.userName) {
                                                                await DeleteUser_Mutation.mutateAsync({ params: { workspaceId: workspaceId, userName: data.userName } })
                                                                queryClient.invalidateQueries({ queryKey: users.queryKey })
                                                                notifications.show({ message: "刪除成功" })
                                                            }
                                                        } catch (e) {
                                                            notifications.show({ message: "刪除失敗", color: "red" })
                                                        }
                                                    },
                                                },

                                            });
                                        }}
                                    >
                                        <IconTrash size={16} />
                                    </ActionIcon>}
                            </Group>
                        ),
                    },
                ]}
                records={users.data.data.sort((a, b) => RoleToNumber(a.role) - RoleToNumber(b.role))
                    .map(x => { return { ...x, id: x.userName } })}
            />
        </>}
    </Box>
}
function RoleToNumber(role?: WorkspaceUserRole) {
    if (role == "Owner") return 0
    if (role == "Manager") return 1
    if (role == "Editor") return 2
    if (role == "Viewer") return 3
    if (role == "None") return 4
    return 5
}