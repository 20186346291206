import { getGetApiWorkspaceListQueryKey, useDeleteApiWorkspaceDelete, useDeleteApiWorkspaceUserLeave, usePutApiWorkspaceRename, Workspace } from "@/API";
import { Text, Card, Group, Button, Menu, ActionIcon, rem, LoadingOverlay, Modal, Flex } from "@mantine/core";
import { WorkspaceUserRoleBadge } from "../WorkspaceUserRoleText";
import { IconDots, IconEdit, IconLogout2, IconShare, IconTrash, IconUser, IconUserFilled } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useAuthStore } from "@/Stores/AuthStore";
import { EditWorkspaceNameForm } from "./EditWorkspaceNameForm";
import { useDisclosure } from "@mantine/hooks";
import { UseWorkspaceAuth } from "../UseWorkspaceAuth";
import { AudioRecordPublicPermissionBadge } from "../AudioRecordPublicPermissionText";
import { WorkspaceShareRecordButton } from "../ShareRecord/WorkspaceShareRecordButton";

export function WorkspaceListCard({ workspace, onEnter }: { workspace: Workspace, onEnter: () => void }) {
    const queryClient = useQueryClient()
    const auth = useAuthStore();
    const workspaceOwnerAuth = UseWorkspaceAuth(workspace.id!, "Owner")
    const deleteWorkspace_Mutation = useDeleteApiWorkspaceDelete()
    var user = useMemo(() => workspace.users?.find(x => x.userName == auth.data.userName), [workspace.users])
    const [editName_opened, { open: editName_open, close: editName_close }] = useDisclosure(false);
    const rename_Mutation = usePutApiWorkspaceRename()
    const [updateLoading, setUpdateLoading] = useState(false)
    const leave_Mutation = useDeleteApiWorkspaceUserLeave()
    return <>
        <Card w={400} shadow="sm" padding="lg" pt={5} radius="md" withBorder>
            <LoadingOverlay
                visible={updateLoading}
                zIndex={1000}
                loaderProps={{ type: 'bars' }}
            />
            <Group>
                <Group mt="md" mb="xs" align="center" gap={5}>
                    <Text fw={500}>{workspace.name}</Text>
                    <WorkspaceUserRoleBadge role={user?.role ?? "None"} />
                    {workspace.publicPermission && workspace.publicPermission != "None" &&
                        <AudioRecordPublicPermissionBadge permission={workspace.publicPermission} />
                    }
                </Group>
                <Flex pos="absolute" right={10} top={15}>
                    <WorkspaceShareRecordButton workspace={workspace}>
                        <ActionIcon
                            size="sm"
                            variant="white"
                        >
                            <IconShare />
                        </ActionIcon>
                    </WorkspaceShareRecordButton>

                    <Menu withinPortal position="bottom-end" shadow="sm">
                        <Menu.Target>
                            <ActionIcon variant="subtle" color="gray">
                                <IconDots style={{ width: rem(16), height: rem(16) }} />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {workspaceOwnerAuth ?
                                <>
                                    <Menu.Item
                                        leftSection={<IconEdit style={{ width: rem(14), height: rem(14) }} />}
                                        color="var(--mantine-primary-color-filled)"
                                        onClick={() => {
                                            editName_open()
                                        }}
                                    >
                                        修改名稱
                                    </Menu.Item>
                                    <Menu.Item
                                        leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
                                        color="red"
                                        onClick={() => {
                                            if (workspace.id) {
                                                modals.openContextModal({
                                                    modal: "ConfirmCountDown",
                                                    title: "刪除工作區域",
                                                    innerProps: {
                                                        countDownSecond: 3,
                                                        children: (
                                                            <Text size="sm">
                                                                是否要刪除工作區域{workspace.name}?
                                                            </Text>
                                                        ),
                                                        labels: { confirm: '刪除', cancel: '取消' },
                                                        confirmProps: { color: 'red', loading: updateLoading },
                                                        onConfirm: async () => {
                                                            setUpdateLoading(true)
                                                            try {
                                                                if (workspace.id)
                                                                    await deleteWorkspace_Mutation.mutateAsync({
                                                                        params: {
                                                                            id: workspace.id
                                                                        }
                                                                    })
                                                                notifications.show({ message: "刪除成功" })
                                                                queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                                                            }
                                                            catch (e) {
                                                                notifications.show({ message: "刪除失敗", color: "red" })
                                                            }
                                                            setUpdateLoading(false)
                                                        }
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        刪除
                                    </Menu.Item>
                                </> : <>
                                    <Menu.Item
                                        leftSection={<IconLogout2 style={{ width: rem(14), height: rem(14) }} />}
                                        color="red"
                                        onClick={() => {
                                            modals.openConfirmModal({
                                                title: '離開工作空間',
                                                centered: true,
                                                children: (
                                                    <Text size="sm">
                                                        是否要離開工作空間"{workspace.name}"?
                                                    </Text>
                                                ),
                                                labels: { confirm: '離開', cancel: "取消" },
                                                confirmProps: { color: 'red' },
                                                onConfirm: async () => {
                                                    if (workspace.id) {
                                                        setUpdateLoading(true)
                                                        try {
                                                            await leave_Mutation.mutateAsync({ params: { workspaceId: workspace.id } })
                                                            queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                                                        } catch (e) {
                                                            notifications.show({ message: "離開工作空間失敗", color: "red" })
                                                        }
                                                        setUpdateLoading(false)
                                                    }
                                                },
                                            });
                                        }}
                                    >
                                        離開
                                    </Menu.Item>
                                </>
                            }
                        </Menu.Dropdown>
                    </Menu>
                </Flex>
            </Group>
            <Group gap={0}>
                <IconUserFilled style={{ height: "20px" }}
                    stroke={1.5}
                    color="gray" />
                <Text c="gray" size="sm">
                    {workspace.users?.length}人
                </Text>
            </Group>
            <Button color="var(--mantine-primary-color-filled)" fullWidth mt="md" radius="md" onClick={() => {
                onEnter()
            }}>
                開啟
            </Button>
            <Modal title="修改名稱" opened={editName_opened} onClose={editName_close}>
                <EditWorkspaceNameForm initValue={workspace.name ?? ""} onSubmit={async v => {
                    editName_close()
                    setUpdateLoading(true)
                    if (workspace.id) {
                        try {
                            await rename_Mutation.mutateAsync({ params: { id: workspace.id, name: v } })
                            notifications.show({ message: "修改成功" })
                            queryClient.invalidateQueries({ queryKey: getGetApiWorkspaceListQueryKey() })
                        } catch (e) {
                            notifications.show({ message: "修改失敗", color: "red" })
                        }
                    }
                    setUpdateLoading(false)
                }} />
            </Modal>
        </Card >
    </>
}
