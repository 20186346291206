import { useGetApiWorkspaceUserCheckPendingUser, usePostApiWorkspaceUserRequestPendingUser } from "@/API";
import { useAuthStore } from "@/Stores/AuthStore";
import { Button, ButtonProps } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";
import { ReactNode } from "@tanstack/react-router";
import { PropsWithChildren, useMemo, useState } from "react";

export function RequestWorkspaceAuthButton(props: { workspaceId: string, children: (payload: { request: () => void, isLoading: boolean, isRequested: boolean }) => React.ReactNode }) {
    const queryClient = useQueryClient()
    const requestAuth_Mutation = usePostApiWorkspaceUserRequestPendingUser()
    const checkPendingUser = useGetApiWorkspaceUserCheckPendingUser({ workspaceId: props.workspaceId })
    const isRequested = useMemo(() => {
        return !!checkPendingUser.data?.data;
    }, [checkPendingUser.data?.data])
    const [requestLoading, setRequestLoading] = useState(false)
    const Request = async () => {
        setRequestLoading(true)
        try {
            await requestAuth_Mutation.mutateAsync({ params: { workspaceId: props.workspaceId } })
            queryClient.invalidateQueries({ queryKey: checkPendingUser.queryKey })
        } catch (e) {
            notifications.show({ message: "發生錯誤", color: "red" })
        }
        setRequestLoading(false)
    }
    return <>
        {props.children({ isRequested: isRequested, isLoading: requestLoading || checkPendingUser.isFetching, request: Request })}
    </>
}