/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PaymentImport } from './routes/_Payment'
import { Route as TTSUsageImport } from './routes/TTSUsage'
import { Route as SettingsImport } from './routes/Settings'
import { Route as PeriodCreditKeyImport } from './routes/PeriodCreditKey'
import { Route as CreditKeyImport } from './routes/CreditKey'
import { Route as AppKeyImport } from './routes/AppKey'
import { Route as IndexImport } from './routes/index'
import { Route as WorkspaceIndexImport } from './routes/workspace/index'
import { Route as NavSelectAppKeyImport } from './routes/nav/SelectAppKey'
import { Route as AdminLinePayImport } from './routes/admin/_LinePay'
import { Route as AdminTTSUsageImport } from './routes/admin/TTSUsage'
import { Route as AdminSettingImport } from './routes/admin/Setting'
import { Route as AdminServiceMetricImport } from './routes/admin/ServiceMetric'
import { Route as AdminLoginImport } from './routes/admin/Login'
import { Route as AdminLicenseImport } from './routes/admin/License'
import { Route as AdminInfoImport } from './routes/admin/Info'
import { Route as AdminAudioToTextBalancerImport } from './routes/admin/AudioToTextBalancer'
import { Route as WorkspaceWorkspaceIdIndexImport } from './routes/workspace/$workspaceId/index'
import { Route as AdminUserManagerIndexImport } from './routes/admin/UserManager/index'
import { Route as WorkspaceWorkspaceIdSettingsImport } from './routes/workspace/$workspaceId/settings'
import { Route as AdminUserManagerUserIdImport } from './routes/admin/UserManager/$userId'
import { Route as WorkspaceWorkspaceIdSettingsUsersImport } from './routes/workspace/$workspaceId/settings/users'
import { Route as WorkspaceWorkspaceIdSettingsPendingUsersImport } from './routes/workspace/$workspaceId/settings/pendingUsers'
import { Route as WorkspaceWorkspaceIdSettingsInfoImport } from './routes/workspace/$workspaceId/settings/info'
import { Route as WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexImport } from './routes/workspace/$workspaceId/audioRecord/$audioRecordId/index'
import { Route as WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleImport } from './routes/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle'

// Create Virtual Routes

const AdminImport = createFileRoute('/admin')()

// Create/Update Routes

const AdminRoute = AdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any)

const PaymentRoute = PaymentImport.update({
  id: '/_Payment',
  getParentRoute: () => rootRoute,
} as any)

const TTSUsageRoute = TTSUsageImport.update({
  id: '/TTSUsage',
  path: '/TTSUsage',
  getParentRoute: () => rootRoute,
} as any)

const SettingsRoute = SettingsImport.update({
  id: '/Settings',
  path: '/Settings',
  getParentRoute: () => rootRoute,
} as any)

const PeriodCreditKeyRoute = PeriodCreditKeyImport.update({
  id: '/PeriodCreditKey',
  path: '/PeriodCreditKey',
  getParentRoute: () => rootRoute,
} as any)

const CreditKeyRoute = CreditKeyImport.update({
  id: '/CreditKey',
  path: '/CreditKey',
  getParentRoute: () => rootRoute,
} as any)

const AppKeyRoute = AppKeyImport.update({
  id: '/AppKey',
  path: '/AppKey',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIndexRoute = WorkspaceIndexImport.update({
  id: '/workspace/',
  path: '/workspace/',
  getParentRoute: () => rootRoute,
} as any)

const NavSelectAppKeyRoute = NavSelectAppKeyImport.update({
  id: '/nav/SelectAppKey',
  path: '/nav/SelectAppKey',
  getParentRoute: () => rootRoute,
} as any)

const AdminLinePayRoute = AdminLinePayImport.update({
  id: '/_LinePay',
  getParentRoute: () => AdminRoute,
} as any)

const AdminTTSUsageRoute = AdminTTSUsageImport.update({
  id: '/admin/TTSUsage',
  path: '/admin/TTSUsage',
  getParentRoute: () => rootRoute,
} as any)

const AdminSettingRoute = AdminSettingImport.update({
  id: '/admin/Setting',
  path: '/admin/Setting',
  getParentRoute: () => rootRoute,
} as any)

const AdminServiceMetricRoute = AdminServiceMetricImport.update({
  id: '/admin/ServiceMetric',
  path: '/admin/ServiceMetric',
  getParentRoute: () => rootRoute,
} as any)

const AdminLoginRoute = AdminLoginImport.update({
  id: '/admin/Login',
  path: '/admin/Login',
  getParentRoute: () => rootRoute,
} as any)

const AdminLicenseRoute = AdminLicenseImport.update({
  id: '/admin/License',
  path: '/admin/License',
  getParentRoute: () => rootRoute,
} as any)

const AdminInfoRoute = AdminInfoImport.update({
  id: '/admin/Info',
  path: '/admin/Info',
  getParentRoute: () => rootRoute,
} as any)

const AdminAudioToTextBalancerRoute = AdminAudioToTextBalancerImport.update({
  id: '/admin/AudioToTextBalancer',
  path: '/admin/AudioToTextBalancer',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceWorkspaceIdIndexRoute = WorkspaceWorkspaceIdIndexImport.update({
  id: '/workspace/$workspaceId/',
  path: '/workspace/$workspaceId/',
  getParentRoute: () => rootRoute,
} as any)

const AdminUserManagerIndexRoute = AdminUserManagerIndexImport.update({
  id: '/UserManager/',
  path: '/UserManager/',
  getParentRoute: () => AdminRoute,
} as any)

const WorkspaceWorkspaceIdSettingsRoute =
  WorkspaceWorkspaceIdSettingsImport.update({
    id: '/workspace/$workspaceId/settings',
    path: '/workspace/$workspaceId/settings',
    getParentRoute: () => rootRoute,
  } as any)

const AdminUserManagerUserIdRoute = AdminUserManagerUserIdImport.update({
  id: '/UserManager/$userId',
  path: '/UserManager/$userId',
  getParentRoute: () => AdminRoute,
} as any)

const WorkspaceWorkspaceIdSettingsUsersRoute =
  WorkspaceWorkspaceIdSettingsUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => WorkspaceWorkspaceIdSettingsRoute,
  } as any)

const WorkspaceWorkspaceIdSettingsPendingUsersRoute =
  WorkspaceWorkspaceIdSettingsPendingUsersImport.update({
    id: '/pendingUsers',
    path: '/pendingUsers',
    getParentRoute: () => WorkspaceWorkspaceIdSettingsRoute,
  } as any)

const WorkspaceWorkspaceIdSettingsInfoRoute =
  WorkspaceWorkspaceIdSettingsInfoImport.update({
    id: '/info',
    path: '/info',
    getParentRoute: () => WorkspaceWorkspaceIdSettingsRoute,
  } as any)

const WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexRoute =
  WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexImport.update({
    id: '/workspace/$workspaceId/audioRecord/$audioRecordId/',
    path: '/workspace/$workspaceId/audioRecord/$audioRecordId/',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleRoute =
  WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleImport.update({
    id: '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle',
    path: '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/AppKey': {
      id: '/AppKey'
      path: '/AppKey'
      fullPath: '/AppKey'
      preLoaderRoute: typeof AppKeyImport
      parentRoute: typeof rootRoute
    }
    '/CreditKey': {
      id: '/CreditKey'
      path: '/CreditKey'
      fullPath: '/CreditKey'
      preLoaderRoute: typeof CreditKeyImport
      parentRoute: typeof rootRoute
    }
    '/PeriodCreditKey': {
      id: '/PeriodCreditKey'
      path: '/PeriodCreditKey'
      fullPath: '/PeriodCreditKey'
      preLoaderRoute: typeof PeriodCreditKeyImport
      parentRoute: typeof rootRoute
    }
    '/Settings': {
      id: '/Settings'
      path: '/Settings'
      fullPath: '/Settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/TTSUsage': {
      id: '/TTSUsage'
      path: '/TTSUsage'
      fullPath: '/TTSUsage'
      preLoaderRoute: typeof TTSUsageImport
      parentRoute: typeof rootRoute
    }
    '/_Payment': {
      id: '/_Payment'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PaymentImport
      parentRoute: typeof rootRoute
    }
    '/admin/AudioToTextBalancer': {
      id: '/admin/AudioToTextBalancer'
      path: '/admin/AudioToTextBalancer'
      fullPath: '/admin/AudioToTextBalancer'
      preLoaderRoute: typeof AdminAudioToTextBalancerImport
      parentRoute: typeof rootRoute
    }
    '/admin/Info': {
      id: '/admin/Info'
      path: '/admin/Info'
      fullPath: '/admin/Info'
      preLoaderRoute: typeof AdminInfoImport
      parentRoute: typeof rootRoute
    }
    '/admin/License': {
      id: '/admin/License'
      path: '/admin/License'
      fullPath: '/admin/License'
      preLoaderRoute: typeof AdminLicenseImport
      parentRoute: typeof rootRoute
    }
    '/admin/Login': {
      id: '/admin/Login'
      path: '/admin/Login'
      fullPath: '/admin/Login'
      preLoaderRoute: typeof AdminLoginImport
      parentRoute: typeof rootRoute
    }
    '/admin/ServiceMetric': {
      id: '/admin/ServiceMetric'
      path: '/admin/ServiceMetric'
      fullPath: '/admin/ServiceMetric'
      preLoaderRoute: typeof AdminServiceMetricImport
      parentRoute: typeof rootRoute
    }
    '/admin/Setting': {
      id: '/admin/Setting'
      path: '/admin/Setting'
      fullPath: '/admin/Setting'
      preLoaderRoute: typeof AdminSettingImport
      parentRoute: typeof rootRoute
    }
    '/admin/TTSUsage': {
      id: '/admin/TTSUsage'
      path: '/admin/TTSUsage'
      fullPath: '/admin/TTSUsage'
      preLoaderRoute: typeof AdminTTSUsageImport
      parentRoute: typeof rootRoute
    }
    '/admin': {
      id: '/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminImport
      parentRoute: typeof rootRoute
    }
    '/admin/_LinePay': {
      id: '/admin/_LinePay'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminLinePayImport
      parentRoute: typeof AdminRoute
    }
    '/nav/SelectAppKey': {
      id: '/nav/SelectAppKey'
      path: '/nav/SelectAppKey'
      fullPath: '/nav/SelectAppKey'
      preLoaderRoute: typeof NavSelectAppKeyImport
      parentRoute: typeof rootRoute
    }
    '/workspace/': {
      id: '/workspace/'
      path: '/workspace'
      fullPath: '/workspace'
      preLoaderRoute: typeof WorkspaceIndexImport
      parentRoute: typeof rootRoute
    }
    '/admin/UserManager/$userId': {
      id: '/admin/UserManager/$userId'
      path: '/UserManager/$userId'
      fullPath: '/admin/UserManager/$userId'
      preLoaderRoute: typeof AdminUserManagerUserIdImport
      parentRoute: typeof AdminImport
    }
    '/workspace/$workspaceId/settings': {
      id: '/workspace/$workspaceId/settings'
      path: '/workspace/$workspaceId/settings'
      fullPath: '/workspace/$workspaceId/settings'
      preLoaderRoute: typeof WorkspaceWorkspaceIdSettingsImport
      parentRoute: typeof rootRoute
    }
    '/admin/UserManager/': {
      id: '/admin/UserManager/'
      path: '/UserManager'
      fullPath: '/admin/UserManager'
      preLoaderRoute: typeof AdminUserManagerIndexImport
      parentRoute: typeof AdminImport
    }
    '/workspace/$workspaceId/': {
      id: '/workspace/$workspaceId/'
      path: '/workspace/$workspaceId'
      fullPath: '/workspace/$workspaceId'
      preLoaderRoute: typeof WorkspaceWorkspaceIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/workspace/$workspaceId/settings/info': {
      id: '/workspace/$workspaceId/settings/info'
      path: '/info'
      fullPath: '/workspace/$workspaceId/settings/info'
      preLoaderRoute: typeof WorkspaceWorkspaceIdSettingsInfoImport
      parentRoute: typeof WorkspaceWorkspaceIdSettingsImport
    }
    '/workspace/$workspaceId/settings/pendingUsers': {
      id: '/workspace/$workspaceId/settings/pendingUsers'
      path: '/pendingUsers'
      fullPath: '/workspace/$workspaceId/settings/pendingUsers'
      preLoaderRoute: typeof WorkspaceWorkspaceIdSettingsPendingUsersImport
      parentRoute: typeof WorkspaceWorkspaceIdSettingsImport
    }
    '/workspace/$workspaceId/settings/users': {
      id: '/workspace/$workspaceId/settings/users'
      path: '/users'
      fullPath: '/workspace/$workspaceId/settings/users'
      preLoaderRoute: typeof WorkspaceWorkspaceIdSettingsUsersImport
      parentRoute: typeof WorkspaceWorkspaceIdSettingsImport
    }
    '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle': {
      id: '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle'
      path: '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle'
      fullPath: '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle'
      preLoaderRoute: typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleImport
      parentRoute: typeof rootRoute
    }
    '/workspace/$workspaceId/audioRecord/$audioRecordId/': {
      id: '/workspace/$workspaceId/audioRecord/$audioRecordId/'
      path: '/workspace/$workspaceId/audioRecord/$audioRecordId'
      fullPath: '/workspace/$workspaceId/audioRecord/$audioRecordId'
      preLoaderRoute: typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface AdminRouteChildren {
  AdminLinePayRoute: typeof AdminLinePayRoute
  AdminUserManagerUserIdRoute: typeof AdminUserManagerUserIdRoute
  AdminUserManagerIndexRoute: typeof AdminUserManagerIndexRoute
}

const AdminRouteChildren: AdminRouteChildren = {
  AdminLinePayRoute: AdminLinePayRoute,
  AdminUserManagerUserIdRoute: AdminUserManagerUserIdRoute,
  AdminUserManagerIndexRoute: AdminUserManagerIndexRoute,
}

const AdminRouteWithChildren = AdminRoute._addFileChildren(AdminRouteChildren)

interface WorkspaceWorkspaceIdSettingsRouteChildren {
  WorkspaceWorkspaceIdSettingsInfoRoute: typeof WorkspaceWorkspaceIdSettingsInfoRoute
  WorkspaceWorkspaceIdSettingsPendingUsersRoute: typeof WorkspaceWorkspaceIdSettingsPendingUsersRoute
  WorkspaceWorkspaceIdSettingsUsersRoute: typeof WorkspaceWorkspaceIdSettingsUsersRoute
}

const WorkspaceWorkspaceIdSettingsRouteChildren: WorkspaceWorkspaceIdSettingsRouteChildren =
  {
    WorkspaceWorkspaceIdSettingsInfoRoute:
      WorkspaceWorkspaceIdSettingsInfoRoute,
    WorkspaceWorkspaceIdSettingsPendingUsersRoute:
      WorkspaceWorkspaceIdSettingsPendingUsersRoute,
    WorkspaceWorkspaceIdSettingsUsersRoute:
      WorkspaceWorkspaceIdSettingsUsersRoute,
  }

const WorkspaceWorkspaceIdSettingsRouteWithChildren =
  WorkspaceWorkspaceIdSettingsRoute._addFileChildren(
    WorkspaceWorkspaceIdSettingsRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/AppKey': typeof AppKeyRoute
  '/CreditKey': typeof CreditKeyRoute
  '/PeriodCreditKey': typeof PeriodCreditKeyRoute
  '/Settings': typeof SettingsRoute
  '/TTSUsage': typeof TTSUsageRoute
  '': typeof PaymentRoute
  '/admin/AudioToTextBalancer': typeof AdminAudioToTextBalancerRoute
  '/admin/Info': typeof AdminInfoRoute
  '/admin/License': typeof AdminLicenseRoute
  '/admin/Login': typeof AdminLoginRoute
  '/admin/ServiceMetric': typeof AdminServiceMetricRoute
  '/admin/Setting': typeof AdminSettingRoute
  '/admin/TTSUsage': typeof AdminTTSUsageRoute
  '/admin': typeof AdminLinePayRoute
  '/nav/SelectAppKey': typeof NavSelectAppKeyRoute
  '/workspace': typeof WorkspaceIndexRoute
  '/admin/UserManager/$userId': typeof AdminUserManagerUserIdRoute
  '/workspace/$workspaceId/settings': typeof WorkspaceWorkspaceIdSettingsRouteWithChildren
  '/admin/UserManager': typeof AdminUserManagerIndexRoute
  '/workspace/$workspaceId': typeof WorkspaceWorkspaceIdIndexRoute
  '/workspace/$workspaceId/settings/info': typeof WorkspaceWorkspaceIdSettingsInfoRoute
  '/workspace/$workspaceId/settings/pendingUsers': typeof WorkspaceWorkspaceIdSettingsPendingUsersRoute
  '/workspace/$workspaceId/settings/users': typeof WorkspaceWorkspaceIdSettingsUsersRoute
  '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle': typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleRoute
  '/workspace/$workspaceId/audioRecord/$audioRecordId': typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/AppKey': typeof AppKeyRoute
  '/CreditKey': typeof CreditKeyRoute
  '/PeriodCreditKey': typeof PeriodCreditKeyRoute
  '/Settings': typeof SettingsRoute
  '/TTSUsage': typeof TTSUsageRoute
  '': typeof PaymentRoute
  '/admin/AudioToTextBalancer': typeof AdminAudioToTextBalancerRoute
  '/admin/Info': typeof AdminInfoRoute
  '/admin/License': typeof AdminLicenseRoute
  '/admin/Login': typeof AdminLoginRoute
  '/admin/ServiceMetric': typeof AdminServiceMetricRoute
  '/admin/Setting': typeof AdminSettingRoute
  '/admin/TTSUsage': typeof AdminTTSUsageRoute
  '/admin': typeof AdminLinePayRoute
  '/nav/SelectAppKey': typeof NavSelectAppKeyRoute
  '/workspace': typeof WorkspaceIndexRoute
  '/admin/UserManager/$userId': typeof AdminUserManagerUserIdRoute
  '/workspace/$workspaceId/settings': typeof WorkspaceWorkspaceIdSettingsRouteWithChildren
  '/admin/UserManager': typeof AdminUserManagerIndexRoute
  '/workspace/$workspaceId': typeof WorkspaceWorkspaceIdIndexRoute
  '/workspace/$workspaceId/settings/info': typeof WorkspaceWorkspaceIdSettingsInfoRoute
  '/workspace/$workspaceId/settings/pendingUsers': typeof WorkspaceWorkspaceIdSettingsPendingUsersRoute
  '/workspace/$workspaceId/settings/users': typeof WorkspaceWorkspaceIdSettingsUsersRoute
  '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle': typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleRoute
  '/workspace/$workspaceId/audioRecord/$audioRecordId': typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/AppKey': typeof AppKeyRoute
  '/CreditKey': typeof CreditKeyRoute
  '/PeriodCreditKey': typeof PeriodCreditKeyRoute
  '/Settings': typeof SettingsRoute
  '/TTSUsage': typeof TTSUsageRoute
  '/_Payment': typeof PaymentRoute
  '/admin/AudioToTextBalancer': typeof AdminAudioToTextBalancerRoute
  '/admin/Info': typeof AdminInfoRoute
  '/admin/License': typeof AdminLicenseRoute
  '/admin/Login': typeof AdminLoginRoute
  '/admin/ServiceMetric': typeof AdminServiceMetricRoute
  '/admin/Setting': typeof AdminSettingRoute
  '/admin/TTSUsage': typeof AdminTTSUsageRoute
  '/admin': typeof AdminRouteWithChildren
  '/admin/_LinePay': typeof AdminLinePayRoute
  '/nav/SelectAppKey': typeof NavSelectAppKeyRoute
  '/workspace/': typeof WorkspaceIndexRoute
  '/admin/UserManager/$userId': typeof AdminUserManagerUserIdRoute
  '/workspace/$workspaceId/settings': typeof WorkspaceWorkspaceIdSettingsRouteWithChildren
  '/admin/UserManager/': typeof AdminUserManagerIndexRoute
  '/workspace/$workspaceId/': typeof WorkspaceWorkspaceIdIndexRoute
  '/workspace/$workspaceId/settings/info': typeof WorkspaceWorkspaceIdSettingsInfoRoute
  '/workspace/$workspaceId/settings/pendingUsers': typeof WorkspaceWorkspaceIdSettingsPendingUsersRoute
  '/workspace/$workspaceId/settings/users': typeof WorkspaceWorkspaceIdSettingsUsersRoute
  '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle': typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleRoute
  '/workspace/$workspaceId/audioRecord/$audioRecordId/': typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/AppKey'
    | '/CreditKey'
    | '/PeriodCreditKey'
    | '/Settings'
    | '/TTSUsage'
    | ''
    | '/admin/AudioToTextBalancer'
    | '/admin/Info'
    | '/admin/License'
    | '/admin/Login'
    | '/admin/ServiceMetric'
    | '/admin/Setting'
    | '/admin/TTSUsage'
    | '/admin'
    | '/nav/SelectAppKey'
    | '/workspace'
    | '/admin/UserManager/$userId'
    | '/workspace/$workspaceId/settings'
    | '/admin/UserManager'
    | '/workspace/$workspaceId'
    | '/workspace/$workspaceId/settings/info'
    | '/workspace/$workspaceId/settings/pendingUsers'
    | '/workspace/$workspaceId/settings/users'
    | '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle'
    | '/workspace/$workspaceId/audioRecord/$audioRecordId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/AppKey'
    | '/CreditKey'
    | '/PeriodCreditKey'
    | '/Settings'
    | '/TTSUsage'
    | ''
    | '/admin/AudioToTextBalancer'
    | '/admin/Info'
    | '/admin/License'
    | '/admin/Login'
    | '/admin/ServiceMetric'
    | '/admin/Setting'
    | '/admin/TTSUsage'
    | '/admin'
    | '/nav/SelectAppKey'
    | '/workspace'
    | '/admin/UserManager/$userId'
    | '/workspace/$workspaceId/settings'
    | '/admin/UserManager'
    | '/workspace/$workspaceId'
    | '/workspace/$workspaceId/settings/info'
    | '/workspace/$workspaceId/settings/pendingUsers'
    | '/workspace/$workspaceId/settings/users'
    | '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle'
    | '/workspace/$workspaceId/audioRecord/$audioRecordId'
  id:
    | '__root__'
    | '/'
    | '/AppKey'
    | '/CreditKey'
    | '/PeriodCreditKey'
    | '/Settings'
    | '/TTSUsage'
    | '/_Payment'
    | '/admin/AudioToTextBalancer'
    | '/admin/Info'
    | '/admin/License'
    | '/admin/Login'
    | '/admin/ServiceMetric'
    | '/admin/Setting'
    | '/admin/TTSUsage'
    | '/admin'
    | '/admin/_LinePay'
    | '/nav/SelectAppKey'
    | '/workspace/'
    | '/admin/UserManager/$userId'
    | '/workspace/$workspaceId/settings'
    | '/admin/UserManager/'
    | '/workspace/$workspaceId/'
    | '/workspace/$workspaceId/settings/info'
    | '/workspace/$workspaceId/settings/pendingUsers'
    | '/workspace/$workspaceId/settings/users'
    | '/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle'
    | '/workspace/$workspaceId/audioRecord/$audioRecordId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppKeyRoute: typeof AppKeyRoute
  CreditKeyRoute: typeof CreditKeyRoute
  PeriodCreditKeyRoute: typeof PeriodCreditKeyRoute
  SettingsRoute: typeof SettingsRoute
  TTSUsageRoute: typeof TTSUsageRoute
  PaymentRoute: typeof PaymentRoute
  AdminAudioToTextBalancerRoute: typeof AdminAudioToTextBalancerRoute
  AdminInfoRoute: typeof AdminInfoRoute
  AdminLicenseRoute: typeof AdminLicenseRoute
  AdminLoginRoute: typeof AdminLoginRoute
  AdminServiceMetricRoute: typeof AdminServiceMetricRoute
  AdminSettingRoute: typeof AdminSettingRoute
  AdminTTSUsageRoute: typeof AdminTTSUsageRoute
  AdminRoute: typeof AdminRouteWithChildren
  NavSelectAppKeyRoute: typeof NavSelectAppKeyRoute
  WorkspaceIndexRoute: typeof WorkspaceIndexRoute
  WorkspaceWorkspaceIdSettingsRoute: typeof WorkspaceWorkspaceIdSettingsRouteWithChildren
  WorkspaceWorkspaceIdIndexRoute: typeof WorkspaceWorkspaceIdIndexRoute
  WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleRoute: typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleRoute
  WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexRoute: typeof WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppKeyRoute: AppKeyRoute,
  CreditKeyRoute: CreditKeyRoute,
  PeriodCreditKeyRoute: PeriodCreditKeyRoute,
  SettingsRoute: SettingsRoute,
  TTSUsageRoute: TTSUsageRoute,
  PaymentRoute: PaymentRoute,
  AdminAudioToTextBalancerRoute: AdminAudioToTextBalancerRoute,
  AdminInfoRoute: AdminInfoRoute,
  AdminLicenseRoute: AdminLicenseRoute,
  AdminLoginRoute: AdminLoginRoute,
  AdminServiceMetricRoute: AdminServiceMetricRoute,
  AdminSettingRoute: AdminSettingRoute,
  AdminTTSUsageRoute: AdminTTSUsageRoute,
  AdminRoute: AdminRouteWithChildren,
  NavSelectAppKeyRoute: NavSelectAppKeyRoute,
  WorkspaceIndexRoute: WorkspaceIndexRoute,
  WorkspaceWorkspaceIdSettingsRoute:
    WorkspaceWorkspaceIdSettingsRouteWithChildren,
  WorkspaceWorkspaceIdIndexRoute: WorkspaceWorkspaceIdIndexRoute,
  WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleRoute:
    WorkspaceWorkspaceIdAudioRecordAudioRecordIdSubtitleRoute,
  WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexRoute:
    WorkspaceWorkspaceIdAudioRecordAudioRecordIdIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/AppKey",
        "/CreditKey",
        "/PeriodCreditKey",
        "/Settings",
        "/TTSUsage",
        "/_Payment",
        "/admin/AudioToTextBalancer",
        "/admin/Info",
        "/admin/License",
        "/admin/Login",
        "/admin/ServiceMetric",
        "/admin/Setting",
        "/admin/TTSUsage",
        "/admin",
        "/nav/SelectAppKey",
        "/workspace/",
        "/workspace/$workspaceId/settings",
        "/workspace/$workspaceId/",
        "/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle",
        "/workspace/$workspaceId/audioRecord/$audioRecordId/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/AppKey": {
      "filePath": "AppKey.tsx"
    },
    "/CreditKey": {
      "filePath": "CreditKey.tsx"
    },
    "/PeriodCreditKey": {
      "filePath": "PeriodCreditKey.tsx"
    },
    "/Settings": {
      "filePath": "Settings.tsx"
    },
    "/TTSUsage": {
      "filePath": "TTSUsage.tsx"
    },
    "/_Payment": {
      "filePath": "_Payment.tsx"
    },
    "/admin/AudioToTextBalancer": {
      "filePath": "admin/AudioToTextBalancer.tsx"
    },
    "/admin/Info": {
      "filePath": "admin/Info.tsx"
    },
    "/admin/License": {
      "filePath": "admin/License.tsx"
    },
    "/admin/Login": {
      "filePath": "admin/Login.tsx"
    },
    "/admin/ServiceMetric": {
      "filePath": "admin/ServiceMetric.tsx"
    },
    "/admin/Setting": {
      "filePath": "admin/Setting.tsx"
    },
    "/admin/TTSUsage": {
      "filePath": "admin/TTSUsage.tsx"
    },
    "/admin": {
      "filePath": "admin",
      "children": [
        "/admin/_LinePay",
        "/admin/UserManager/$userId",
        "/admin/UserManager/"
      ]
    },
    "/admin/_LinePay": {
      "filePath": "admin/_LinePay.tsx",
      "parent": "/admin"
    },
    "/nav/SelectAppKey": {
      "filePath": "nav/SelectAppKey.tsx"
    },
    "/workspace/": {
      "filePath": "workspace/index.tsx"
    },
    "/admin/UserManager/$userId": {
      "filePath": "admin/UserManager/$userId.tsx",
      "parent": "/admin"
    },
    "/workspace/$workspaceId/settings": {
      "filePath": "workspace/$workspaceId/settings.tsx",
      "children": [
        "/workspace/$workspaceId/settings/info",
        "/workspace/$workspaceId/settings/pendingUsers",
        "/workspace/$workspaceId/settings/users"
      ]
    },
    "/admin/UserManager/": {
      "filePath": "admin/UserManager/index.tsx",
      "parent": "/admin"
    },
    "/workspace/$workspaceId/": {
      "filePath": "workspace/$workspaceId/index.tsx"
    },
    "/workspace/$workspaceId/settings/info": {
      "filePath": "workspace/$workspaceId/settings/info.tsx",
      "parent": "/workspace/$workspaceId/settings"
    },
    "/workspace/$workspaceId/settings/pendingUsers": {
      "filePath": "workspace/$workspaceId/settings/pendingUsers.tsx",
      "parent": "/workspace/$workspaceId/settings"
    },
    "/workspace/$workspaceId/settings/users": {
      "filePath": "workspace/$workspaceId/settings/users.tsx",
      "parent": "/workspace/$workspaceId/settings"
    },
    "/workspace/$workspaceId/audioRecord/$audioRecordId/subtitle": {
      "filePath": "workspace/$workspaceId/audioRecord/$audioRecordId/subtitle.tsx"
    },
    "/workspace/$workspaceId/audioRecord/$audioRecordId/": {
      "filePath": "workspace/$workspaceId/audioRecord/$audioRecordId/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
