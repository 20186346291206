import { getApiAudioRecordTextDocumentGet, TextDocument, useDeleteApiAudioRecordTextDocumentDelete, useGetApiAudioRecordGet, useGetApiAudioRecordTextDocumentList, usePostApiAudioRecordTextDocumentCreate, usePutApiAudioRecordTextDocumentUpdate } from "@/API"
import { ActionIcon, Box, Button, Center, Flex, LoadingOverlay, Progress, Tabs, Text } from "@mantine/core"
import { useBlocker, useParams, useSearch } from "@tanstack/react-router"
import { useEffect, useState } from "react"
import { TextDocumentItem } from "./TextDocumentItem"
import { modals } from "@mantine/modals"
import { useQueryClient } from "@tanstack/react-query"
import { notifications } from "@mantine/notifications"
import { IconClipboardList, IconFileMinus, IconFilePlus } from "@tabler/icons-react";
import { UseWorkspaceAuth } from "@/Workspaces/UseWorkspaceAuth"
import { useAudioRecordStateStore } from "../AudioRecordStateStore"
import { ChooseSummaryTemplateButton } from "./SummarizationTemplate/ChooseSummaryTemplate"

export function TextDocumentList() {
    const queryClient = useQueryClient()
    const state = useAudioRecordStateStore()
    const { workspaceId, audioRecordId } = useParams({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId/" })
    const { docId } = useSearch({ from: "/workspace/$workspaceId/audioRecord/$audioRecordId/" })
    const create_Mutation = usePostApiAudioRecordTextDocumentCreate()
    const update_Mutation = usePutApiAudioRecordTextDocumentUpdate()
    const delete_Mutation = useDeleteApiAudioRecordTextDocumentDelete()
    const audioRecord = useGetApiAudioRecordGet({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const list = useGetApiAudioRecordTextDocumentList({ workspaceId: workspaceId, audioRecordId: audioRecordId })
    const [selectDocumentId, setSelectDocumentId] = useState(docId)
    const [textDocument, setTextDocument] = useState<TextDocument>()
    const [summarying, setSummarying] = useState(false)
    const [summaryProgress, setSummaryProgress] = useState(0)
    const workspaceEditorAuth = UseWorkspaceAuth(workspaceId, "Editor")
    useEffect(() => {
        if (selectDocumentId)
            getApiAudioRecordTextDocumentGet({ workspaceId: workspaceId, textDocumentId: selectDocumentId }).then(x => {
                setTextDocument(x.data)
            })
        else
            setTextDocument(undefined)
    }, [selectDocumentId])
    useEffect(() => {
        var target = list.data?.data.results?.find(x => x.id == selectDocumentId)
        if (target) {
            var id = target.id
            setSelectDocumentId(id!);
        }
        else {
            var id = list.data?.data.results?.at(0)?.id
            setSelectDocumentId(id!);
        }
    }, [list.data?.data.results])
    useBlocker({
        blockerFn: () => window.confirm('直接離開會中斷生成摘要，確定要直接離開嗎?'),
        condition: summarying,
    })
    return <Flex direction="column" h="100%" mx={15}>
        <LoadingOverlay
            visible={list.isFetching}
            zIndex={1000}
            loaderProps={{ type: 'bars' }}
        />
        {workspaceEditorAuth &&
            <Box w="130px">
                {
                    audioRecord.data &&
                    <ChooseSummaryTemplateButton audioRecord={audioRecord.data?.data} onProgress={async p => {
                        if (p <= 0) setSummarying(true)
                        setSummaryProgress(p)
                    }} onEnd={async doc => {
                        if (doc) {
                            if (doc && doc.id && !state.needSave) setSelectDocumentId(doc.id)
                            await queryClient.invalidateQueries({ queryKey: list.queryKey })
                            notifications.show({ message: "已生成摘要" })
                        } else {
                            notifications.show({ message: "摘要失敗", color: "red" })
                        }
                        setSummarying(false)
                        setSummaryProgress(0)
                    }} >
                        <Button size="compact-sm" disabled={summarying} leftSection={<IconClipboardList stroke={1.5} size={20} />}>
                            {
                                summarying ? <Progress w={100} animated value={summaryProgress * 100} /> : "生成摘要"
                            }
                        </Button>
                    </ChooseSummaryTemplateButton>
                }
                {/* <CreateSummaryButton workspaceId={workspaceId} audioRecordId={audioRecordId} onStart={() => setSummarying(true)} onEnd={async (success, msg, doc) => {
                    if (success) {
                        notifications.show({ message: "已生成摘要" })
                        if (doc && doc.id && !state.needSave) setSelectDocumentId(doc.id)
                        await queryClient.invalidateQueries({ queryKey: list.queryKey })
                    }
                    else
                        notifications.show({ message: "摘要失敗" + (msg ? ":" + msg : ""), color: "red" })
                    setSummarying(false)
                }}>
                    <Button size="compact-sm" loading={summarying} leftSection={<IconClipboardList stroke={1.5} size={20} />}>
                        生成摘要
                    </Button>
                </CreateSummaryButton > */}
            </Box>
        }
        <Flex align="center">
            <Tabs value={selectDocumentId} onChange={v => {
                if (state.needSave && !window.confirm('確定要不保存離開嗎?'))
                    return;
                if (v) setSelectDocumentId(v);
            }}>
                <Tabs.List>
                    {list.data?.data.results?.map(x =>
                        <Tabs.Tab key={`${x.id}`} value={x.id!} >
                            <Text maw={100} size="sm" truncate="end">
                                {x.name}
                            </Text>
                        </Tabs.Tab>)}
                </Tabs.List>
            </Tabs>
            {workspaceEditorAuth && <>
                <ActionIcon variant="subtle" onClick={() => {
                    modals.openConfirmModal({
                        title: '新增文件',
                        children: (
                            <Text size="sm">
                                是否要新增文件
                            </Text>
                        ),
                        labels: { confirm: '確定', cancel: '取消' },
                        onConfirm: async () => {
                            try {
                                var result = await create_Mutation.mutateAsync({ params: { name: "新文件", workspaceId: workspaceId, audioRecordId: audioRecordId } })
                                if (result.data.id && !state.needSave) setSelectDocumentId(result.data.id)
                                await queryClient.invalidateQueries({ queryKey: list.queryKey })
                                notifications.show({ message: "新增成功" })
                            } catch (e) {
                                notifications.show({ message: "新增失敗", color: "red" })
                            }
                        },
                    });
                }}><IconFilePlus /></ActionIcon>
                {selectDocumentId && <ActionIcon variant="subtle" color="red" onClick={() => {
                    modals.openConfirmModal({
                        title: '刪除文件',
                        children: (
                            <Text size="sm">
                                是否要刪除文件"{list.data?.data.results?.find(x => x.id == selectDocumentId)?.name ?? ""}"
                            </Text>
                        ),
                        labels: { confirm: '確定', cancel: '取消' },
                        confirmProps: { color: "red" },
                        onConfirm: async () => {
                            try {
                                var nextIdx = Math.max((list.data?.data.results?.findIndex(x => x.id == selectDocumentId) ?? 0) - 1, 0)
                                var nextId = list.data?.data.results?.at(nextIdx)?.id ?? ""
                                await delete_Mutation.mutateAsync({ params: { workspaceId: workspaceId, textDocumentId: selectDocumentId } })
                                setSelectDocumentId(nextId)
                                await queryClient.invalidateQueries({ queryKey: list.queryKey })
                                notifications.show({ message: "刪除成功" })
                            } catch (e) {
                                notifications.show({ message: "刪除失敗", color: "red" })
                            }
                        },
                    });
                }}><IconFileMinus /></ActionIcon>}
            </>}
        </Flex>
        {list.data?.data.results?.length == 0 ?
            <Center h="100%" p={20}>
                <Text fw={500} size="40px" opacity={0.4} c="gray">請生成逐字稿後點選「生成摘要」
                </Text>
            </Center> :
            <>
                {textDocument && <TextDocumentItem key={textDocument.id} document={textDocument} />}
            </>}
    </Flex>
}